/*! -------------------------------------------------------------------------------------------
JAVASCRIPT main engine!

* @Version:    1.0 - 2016
* @author:     Burocratik
* @email:      hello@burocratik.com
* @website:    http://www.burocratik.com
* @preserve
NOTES:
:: js-no-ajax class on body (nao pode ser no html) > take it off with js as soon I work with ajax
:: js-no-ajax = did refresh
:: body.js-byrefresh= when i start by direct link (refresh) do no show content before loading
:: #loading-page.js-loading-page = i need separate byrefresh of this when I have js off
:: js-loading-page = can be used if I need a global style only when I am loading content
:: mobile = tag html is via js, tag body is via php (can't be on html tag or is deleted), also used for IE<=10
:: _global_allowNavigate = do not allow multiple cliks to load ajax (arrow, keys, click)
:: js-no-transPage = when I want a domain link with no transition ajax animation
--------------------------------------------------------------------------------------------*/
$(document).ready(function () {
  //** outdatedbrowser.com
  // Must be the first to be call or in older browsers IE6,7 will have weird js erros on my code, and the plugin will not work
  outdatedBrowser({
    bgColor: "#f25648",
    color: "#fff",
    lowerThan: "borderImage",
    languagePath: "",
  });
  //** MODERNIZR not supporter properties
  Modernizr.addTest("backgroundcliptext", function () {
    var div = document.createElement("div");
    div.style.cssText = Modernizr._prefixes.join("background-clip:text;");
    return !!div.style.cssText.replace(/\s/g, "").length;
  });
  Modernizr.addTest("object-fit", !!Modernizr.prefixed("objectFit"));

  //BURO SIGNATURE CONSOLE
  if (
    navigator.userAgent.toLowerCase().indexOf("chrome") > -1 ||
    navigator.userAgent.toLowerCase().indexOf("firefox") > -1
  ) {
    var e = [
      "%c Made by Büro %c https://burocratik.com %c 🤘 %c\n",
      "color: #fff; background: #0020f4; padding:5px 0;",
      "color: #fff; background: #242424; padding:5px 0 5px 5px;",
      "background: #242424; padding:5px 0",
      "background: #242424; padding:5px 5px 5px 0",
    ];
    window.console.log.apply(console, e);
  } else {
    window.console &&
      window.console.log("Made by Büro - https://burocratik.com");
  }
  /////////////////////////
}); //END LOAD DOCUMENT

/********************************************************************************************
 **                                                                                       **
      =LOADING PAGES, SECTIONS - =transitions between pages, =ajax
 **                                                                                       **
*********************************************************************************************/
//** MAIN LOAD
function loadPages(newContent, pageTransition) {
  var $currentPage = $(".page-main.page-current"),
    $nextContent = $(".page-main.page-next"); // can't be global

  $("html,body").addClass("fixed-all");
  $_body.removeClass("js-no-ajax"); // I am using =ajax
  $_body.addClass("js-loading-page"); // loading by ajax (removed onStartPage())

  if (pageTransition == "home-page") {
    $nextContent.load(newContent + " .page-toload", function (
      response,
      status,
      xhr
    ) {
      var $this = $(this),
        $headerHeight = $this.find(".header-container").height();
      if (!$this.html()) {
        //=404
        window.location = newContent;
        return false;
      }

      // ANIMATION GOES HERE
      TweenMax.fromTo(
        $currentPage,
        0,
        {
          opacity: 1,
        },
        {
          opacity: 0,
          onComplete: function () {
            manageBodyClasses();
            clearPagesAfterloading(0);

            $("html,body").removeClass("fixed-all");

            TweenMax.fromTo(
              $nextContent,
              0,
              {
                opacity: 0,
              },
              {
                opacity: 1,
                onComplete: function () {
                  TweenMax.set($("#bottle-mobile"), { autoAlpha: 0 });
                  TweenMax.set(
                    $(".bottle-slider").eq(0).find(".bottle-photo img"),
                    { y: 60, opacity: 1 }
                  );
                  TweenMax.to($(".arrow-container"), 2, {
                    opacity: 1,
                    ease: Expo.easeOut,
                  });
                  $("#slogan").addClass("change-spacing");
                  manageBodyClasses();
                  TweenMax.set($(".scroll-helper"), { autoAlpha: 1 });
                  TweenMax.set($(".scroll-helper").find(".char"), {
                    opacity: 1,
                  });
                },
              }
            );
          },
        }
      );
      $.doTimeout(1000, function () {
        TweenMax.set($memeLinkVelhotes, { autoAlpha: 1 });
        TweenMax.set($memeLinkCria, { autoAlpha: 1 });
        TweenMax.staggerTo(
          $memeLinkCria.find(".char"),
          0.4,
          { y: 100, autoAlpha: 1, ease: Expo.easeOut },
          0.1
        );
        TweenMax.staggerTo(
          $memeLinkVelhotes.find(".char"),
          1.4,
          { y: 0, delay: 0.1, autoAlpha: 1, ease: Expo.easeIn },
          0.1
        );
      });
    }); // end nextContent load
    return;
  } else if (pageTransition == "single-memes") {
    $nextContent.load(newContent + " .single-meme-lightbox", function (
      response,
      status,
      xhr
    ) {
      var $this = $(this),
        $headerHeight = $this.find(".header-container").height();
      if (!$this.html()) {
        //=404
        window.location = newContent;
        return false;
      }

      var $image = $_body
        .children(".single-meme-lightbox")
        .find(".image-wrapper .single-meme-image");

      var $clonedImg = $nextContent
        .find(".single-meme-lightbox .single-meme-image")
        .clone();

      $clonedImg.addClass("fake-transition");

      TweenMax.set($clonedImg, {
        position: "absolute",
        top: $_body
          .children(".single-meme-lightbox")
          .find(".image-wrapper")
          .css("padding-top")
          .split("px")[0],
        left: $_body
          .children(".single-meme-lightbox")
          .find(".image-wrapper")
          .css("padding-left")
          .split("px")[0],
        width: $image.outerWidth(),
        height: $image.outerHeight(),
        transformStyle: "preserve-3d",
        backfaceVisibility: "hidden",
        transformOrigin: "top center",
        rotationX: 120,
      });

      $_body
        .children(".single-meme-lightbox")
        .find(".image-wrapper")
        .append($clonedImg);

      TweenMax.to(
        $_body
          .children(".single-meme-lightbox")
          .find(".image-wrapper .fake-transition"),
        1,
        {
          rotationX: 0,
          ease: Expo.easeOut,
          onComplete: function () {
            $_body
              .children(".single-meme-lightbox")
              .html($nextContent.find(".single-meme-lightbox > *"));

            $nextContent.html("");

            _global_allowNavigate = true;

            $.doTimeout(500, function () {
              FB.XFBML.parse();
            });
          },
        }
      );
    });

    return;
  } else if (pageTransition == "open-single-memes") {
    $nextContent.load(newContent + " .single-meme-lightbox", function (
      response,
      status,
      xhr
    ) {
      var $this = $(this),
        $headerHeight = $this.find(".header-container").height();
      if (!$this.html()) {
        //=404
        window.location = newContent;
        return false;
      }

      TweenMax.set($nextContent.find(".single-meme-lightbox"), {
        autoAlpha: 0,
      });

      $nextContent
        .find(".single-meme-lightbox .close-single-meme")
        .addClass("js-active");

      $_body.addClass("single-memes");
      $_body.append($nextContent.find(".single-meme-lightbox"));
      $nextContent.html("");

      TweenMax.fromTo(
        $_body.find(".single-meme-lightbox"),
        0.5,
        {
          autoAlpha: 0,
          scale: 0.98,
        },
        {
          autoAlpha: 1,
          scale: 1,
          ease: Expo.easeInOut,
        }
      );

      $.doTimeout(500, function () {
        FB.XFBML.parse();
      });

      _global_allowNavigate = true;
    });

    return;
  } else {
    $nextContent.load(newContent + " .page-toload", function (
      response,
      status,
      xhr
    ) {
      var $this = $(this);
      if (!$this.html()) {
        //=404
        window.location = newContent;
        return false;
      }

      $_toPreload = $nextContent.find(".preload");

      $_toPreload.imagesLoaded(function ($images, $proper, $broken) {
        var fPreload = $(this).imagesLoaded();
        fPreload.always(function () {
          // console.log($images);

          $("#header-main").addClass("active");
          $("#header-main .letters").addClass("add-transition");

          // ANIMATION GOES HERE
          TweenMax.fromTo(
            $currentPage,
            0.2,
            {
              opacity: 1,
            },
            {
              opacity: 0,
              onComplete: function () {
                manageBodyClasses();
                clearPagesAfterloading(0);

                $("html,body").removeClass("fixed-all");

                TweenMax.fromTo(
                  $nextContent,
                  0.6,
                  {
                    opacity: 0,
                  },
                  {
                    opacity: 1,
                    onComplete: function () {
                      if (
                        $nextContent
                          .find(".page-toload")
                          .hasClass("legado-page")
                      ) {
                        $_loadingPageLegado.addClass("js-loading-page");
                        // $nextContent.find(".arrive").css("height", $(".arrive").find("video").height());
                        // $nextContent.find(".page-header").css("height", $(".page-header").find("img").height());
                        // $nextContent.find(".frame-legacy .pattern-img").css("height", $(".frame-legacy video").height());
                      }
                    },
                  }
                );
              },
            }
          );
        }); //end always
      }); //end preload images
    }); // end nextContent load
  }
  return;
} //////end function main load content

function clearPagesAfterloading(delay) {
  var $currentPage = $(".page-main.page-current"),
    $nextContent = $(".page-main.page-next"); // can't be global

  $.doTimeout(delay, function () {
    $currentPage.remove();
    $nextContent.removeClass("page-next");
    $nextContent.addClass("page-current").removeAttr("aria-hidden");
    var $newCurrentPage = $(".page-main.page-current");
    $newCurrentPage.after(
      '<div class="page-main page-next" aria-hidden="true"></div>'
    );

    onStartPageWhenRefresh(false);
    $newCurrentPage.attr("style", "");
    window.scrollTo(0, 0);
    $("html,body").scrollTop(0);
  }); //end do timeout
} //end function

/********************************************************************************************
 **                                                                                       **
     =START EACH PAGE - refresh or ajax
 **                                                                                       **
*********************************************************************************************/
function onStartPageWhenRefresh(byRefresh) {
  if (byRefresh) {
    // :BUG CHROME: even wit this is not scrolling top is some section, needed hack after preloading with animate
    window.scrollTo(0, 0);
    $("html,body").scrollTop(0);
    //
    $("html,body").addClass("fixed-all");
    $_loadingPage.addClass("js-loading-page");

    $_body.removeClass("js-byrefresh");
    manageBodyClasses();

    // if($_body.hasClass("legado"))
    //   $_loadingPageLegado.addClass('js-loading-page');

    if ($_html.hasClass("ie")) {
      TweenMax.to(".velhotes-logo svg", 2, {
        scale: 2,
        autoAlpha: 1,
        ease: Expo.easeOut,
      });
    } else if ($_body.hasClass("legal") || $_body.hasClass("covid")) {
      TweenMax.to(".velhotes-logo svg", 2, {
        y: 0,
        scale: 1,
        autoAlpha: 1,
        ease: Expo.easeOut,
      });
    } else {
      TweenMax.to(".velhotes-logo svg", 2, {
        scale: 1.4,
        autoAlpha: 1,
        ease: Expo.easeOut,
      });
    }

    $(".bg-layer").addClass("show");
    $.doTimeout(800, function () {
      $(".legal-btn").removeClass("show");
    });

    var x = $(".ink-mask .bg-layer.show h3").offset(),
      marT = $(".ink-mask .bg-layer.show h3").css("marginTop"),
      h = $(".velhotes-logo").height(),
      r = parseInt(marT),
      topX = x.top;

    if (!$_body.hasClass("phone")) {
      TweenMax.set($(".velhotes-logo"), {
        y: topX - h * 5 - r * 1.3,
        force3D: true,
      });
    } else {
      TweenMax.set($(".velhotes-logo"), {
        y: topX - h * 5 - r / 2,
        force3D: true,
      });
    }

    if ($_body.hasClass("legal") || $_body.hasClass("covid")) {
      TweenMax.set($(".velhotes-logo"), { y: 0, force3D: true });
      TweenMax.set($(".velhotes-logo svg"), {
        scale: 1,
        y: 0,
        autoAlpha: 1,
        force3D: true,
      });
    }

    if (cookie.get("age-check") == "true" || $("body").hasClass("covid")) {
      if (
        $_body.hasClass("archive-memes") ||
        $_body.hasClass("single-memes") ||
        $_body.hasClass("legado") ||
        $_body.hasClass("legal") ||
        $_body.hasClass("covid")
      ) {
        if (!$_body.hasClass("phone")) {
          if (!$_html.hasClass("ie")) {
            TweenMax.to($("#header-main").find(".velhotes-logo"), 0.8, {
              y: 0,
              force3D: true,
              ease: Expo.easeOut,
              onComplete: function () {
                TweenMax.set($("#header-main").find(".velhotes-logo"), {
                  clearProps: "all",
                });
                $("#header-main").find(".velhotes-logo").addClass("can-anime");
              },
            });
            TweenMax.to($("#header-main").find(".header-global"), 0.8, {
              y: 0,
              force3D: true,
              ease: Expo.easeOut,
            });
          }
        } else {
          TweenMax.set($(".velhotes-logo"), { y: 0, force3D: true });
        }
      }

      if ($_html.hasClass("ie")) {
        if (
          $_body.hasClass("archive-memes") ||
          $_body.hasClass("single-memes") ||
          $_body.hasClass("legado") ||
          $_body.hasClass("legal") ||
          $_body.hasClass("covid")
        ) {
          TweenMax.to(".velhotes-logo svg", 1, {
            y: 0,
            scale: 1.4,
            autoAlpha: 1,
            ease: Expo.easeOut,
          });
        }
      } else {
        $.doTimeout(100, function () {
          if (
            $_body.hasClass("archive-memes") ||
            $_body.hasClass("single-memes") ||
            $_body.hasClass("legado") ||
            $_body.hasClass("legal") ||
            $_body.hasClass("covid")
          ) {
            TweenMax.to(".velhotes-logo svg", 1, {
              y: 0,
              scale: 1,
              autoAlpha: 1,
              ease: Expo.easeOut,
            });
          }
        });
      }
      $.doTimeout(100, function () {
        if (
          $_body.hasClass("archive-memes") ||
          $_body.hasClass("single-memes") ||
          $_body.hasClass("legado") ||
          $_body.hasClass("legal") ||
          $_body.hasClass("covid")
        ) {
          $("#header-main").addClass("active");
          // TweenMax.to(".velhotes-logo", 1, {y: 0, ease: Expo.easeOut});
          breakTitleLetters($("#cria-o-meme"));
          _customScroll.registerEvents(/scroll/, /wheel/, /touchstart/);
        }
      });
      $.doTimeout(500, function () {
        if (
          $_body.hasClass("archive-memes") ||
          $_body.hasClass("single-memes") ||
          $_body.hasClass("legado") ||
          $_body.hasClass("legal") ||
          $_body.hasClass("covid")
        ) {
          TweenMax.set($("#cria-o-meme"), { autoAlpha: 1 });
          TweenMax.staggerTo(
            $("#cria-o-meme").find(".char"),
            1.4,
            { y: 0, autoAlpha: 1, ease: Expo.easeOut },
            0.1
          );
        }
      });
      $.doTimeout(900, function () {
        if (
          $_body.hasClass("archive-memes") ||
          $_body.hasClass("single-memes") ||
          $_body.hasClass("legado") ||
          $_body.hasClass("legal") ||
          $_body.hasClass("covid")
        ) {
          $("#smile-container").addClass("openSmile");
          $("#create-meme-btn").addClass("openFace");
        }
      });
      $.doTimeout(1300, function () {
        if (
          $_body.hasClass("archive-memes") ||
          $_body.hasClass("single-memes") ||
          $_body.hasClass("legado") ||
          $_body.hasClass("legal") ||
          $_body.hasClass("covid")
        ) {
          TweenMax.to(
            "#smile-container #wink-container span:first-of-type",
            0.6,
            { rotation: 35, force3D: true, ease: Expo.easeOut }
          );
          TweenMax.to(
            "#smile-container #wink-container span:last-of-type",
            0.6,
            { rotation: -35, force3D: true, ease: Expo.easeOut }
          );
        }
      });
    }

    $_toPreload.imagesLoaded(function ($images, $proper, $broken) {
      var fPreload = $(this).imagesLoaded();
      fPreload.always(function () {
        //console.log($images);
        $("html,body").animate(
          {
            scrollTop: 0,
          },
          100
        ); // :BUG CHROME: 100ms is arbitrary
        // $_toPreload.remove(); because i need for section name

        // if browser does not suport object-fit: cover
        if ($_html.hasClass("no-object-fit")) {
          var $element = $(".page-current .element-cover");
          resizeLikeCover($element);
        }

        ///
        TweenMax.to($_loadingPage, 0.6, {
          opacity: 0,
          ease: Power2.easeInOut,
          onComplete: function () {
            $("html,body").removeClass("fixed-all");
            $_loadingPage.removeClass("js-loading-page").hide();
            onStartPage();
          },
        });
      }); //end always
    }); //end preload images

    //for history: When your page loads, it might have a non-null state object and the page will receive an onload event, but no popstate event.
    //forPopstate=true;
  } else {
    onStartPage();
  }
} //////end function

/*-------------------------------------------------------------------------------------------
    =STARTPAGE - EACH PAGE - call of functions and events
--------------------------------------------------------------------------------------------*/
function onStartPage() {
  var do_home, do_legal, do_meme, do_legado;

  // ** =ALLOW user load other pages
  _global_allowNavigate = true;

  window.cancelAnimationFrame(_raf_loop_id);

  // ** =REMOVE classes of loading (if needed)
  $("html,body").removeClass("fixed-all");
  $_body.removeClass("js-loading-page");

  if (_customScroll != null) {
    _customScroll.update();
  }
  if (
    $_scrollContentWrapper.attr("data-scrollbar") != undefined &&
    !$_scrollContentWrapper.hasClass("js-scroll")
  ) {
    _customScroll = Scrollbar.init($_scrollContentWrapper[0], {
      speed: 0.8,
      syncCallbacks: true,
    });
    $_scrollContentWrapper.addClass("js-scroll");
  }
  if (_customScroll != null) {
    $(".loading-container").css({
      top: 0,
    });
    _customScroll.setPosition(0, 0);
    _customScroll.removeListener(_page_scroll_listener);
  }

  // **=POLYFILL ASYNC
  callAsyncFunctions();

  // ** =HOME
  $(".page-current .home-page").length ? (do_home = true) : (do_home = false);
  homePage(do_home);

  // ** =MEME
  $(".page-current .archive-memes-page").length
    ? (do_meme = true)
    : (do_meme = false);
  memePage(do_meme);
  // ** =MEME
  $(".page-current .single-memes-page").length
    ? (do_meme = true)
    : (do_meme = false);
  memePage(do_meme);
  //LEGAL
  $(".page-current .legal-page").length
    ? (do_legal = true)
    : (do_legal = false);
  legalPage(do_legal);
  //LEGADO
  $(".page-current .legado-page").length
    ? (do_legado = true)
    : (do_legado = false);
  legadoPage(do_legado);
  //COVID
  $(".page-current .covid-page").length
    ? (do_covid = true)
    : (do_covid = false);
  covidPage(do_covid);
  //COCKTAILS
  $(".page-current .cocktails-page").length
    ? (do_cocktails = true)
    : (do_cocktails = false);
  cocktailsPage(do_cocktails);

  // ** =scrolling events
  //whenScrolling(true);

  // ** =URL com ancoras onload
  var hasHash = window.location.hash;
  if (hasHash != "") {
    var $toGoHere = $("" + hasHash + "");
    $.doTimeout(500, function () {
      goTo($toGoHere, 1500, [0.7, 0, 0.3, 1], 0);
    });
    //
  }

  // ** =RESIZE ELEMENTS LIKE BACKGROUND COVER (browser does not support object-fit: cover)
  if ($_html.hasClass("no-object-fit")) {
    var $element = $(".page-current .element-cover");
    resizeLikeCover($element);
  }
} //////end function StartPage

/*******************************************************************************************
 **                                                                                       **
    =initGlobalAnimations
 **                                                                                       **
*********************************************************************************************/
function initGlobalAnimations() {
  if (_customScroll != null) _scrollLimit = _customScroll.limit.y;
  else _scrollLimit = $_body.height();

  _raf_main_id = _rAF_loop(animationLoop);
  animationLoop();

  function animationLoop() {
    _rAF_loop(animationLoop);

    if (_customScroll != null) _scrollY = _customScroll.offset.y;
    else _scrollY = $_window.scrollTop();

    if (_lastScroll != _scrollY && _scrollY != 0 && !_blockAll) {
      if (_lastScroll < _scrollY) _scrollDirection = "down";
      else _scrollDirection = "up";

      _lastScroll = _scrollY;
    }
  }
}

/*******************************************************************************************
 **                                                                                       **
    =MAIN =NAVIGATION and FORM NEWSLETTER
 **                                                                                       **
*********************************************************************************************/
function mainNavigation() {
  var $openMenu = $(".open-menu"),
    $offMenu = $(".offmenu"),
    $outsideBg = $offMenu.find(".outside-bg"),
    $outsideOverlay = $offMenu.find(".outside-overlay"),
    $mainNavigation = $offMenu.find(".main-navigation"),
    $subNavigation = $offMenu.find(".sub-navigation"),
    $animeBg = $offMenu.find(".anime-bg"),
    $textOpen = $openMenu.find(".text.open"),
    $textClose = $openMenu.find(".text.close");

  $offMenu.find(".main-navigation li").on("click", function () {
    var $this = $(this);

    if ($this.hasClass("active")) return false;

    $offMenu.find(".main-navigation li").removeClass("active");
    $this.addClass("active");
    $openMenu.click();
  });

  $openMenu
    .on("mouseenter", function () {
      var $this = $(this);

      if ($openMenu.hasClass("js-open") || $_body.hasClass("mobile"))
        return false;

      TweenMax.set($this.find(".burguer:nth-child(1)"), { y: 2 });
      TweenMax.set($this.find(".burguer:nth-child(3)"), { y: -2 });
    })
    .on("mouseleave", function () {
      var $this = $(this);

      if ($openMenu.hasClass("js-open") || $_body.hasClass("mobile"))
        return false;

      TweenMax.set(
        [
          $this.find(".burguer:nth-child(1)"),
          $this.find(".burguer:nth-child(3)"),
        ],
        { y: 0 }
      );
    });

  $openMenu.on("click", function () {
    $_body.toggleClass("js-open-offmenu");
    $openMenu.addClass("js-open");

    if ($_body.hasClass("js-open-offmenu")) {
      _customScroll.unregisterEvents(/scroll/, /wheel/, /touchstart/);

      TweenMax.set($textClose, {
        autoAlpha: 1,
      });

      TweenMax.staggerTo(
        $textClose.find(".char"),
        1.4,
        {
          y: "0%",
          autoAlpha: 1,
          delay: 0.25,
          ease: Expo.easeOut,
        },
        0.1
      );

      TweenMax.staggerTo(
        $textOpen.find(".char"),
        1.4,
        {
          y: "100%",
          autoAlpha: 0,
          ease: Expo.easeOut,
        },
        0.1
      );

      TweenMax.set($openMenu.find(".burguer:nth-child(1)"), {
        y: 7,
        onComplete: function () {
          TweenMax.set($openMenu.find(".burguer:nth-child(2)"), {
            autoAlpha: 0,
            delay: 0.85,
          });
          TweenMax.set($openMenu.find(".burguer:nth-child(1)"), {
            rotation: 40,
            delay: 0.85,
          });
        },
      });

      TweenMax.set($openMenu.find(".burguer:nth-child(3)"), {
        y: -7,
        onComplete: function () {
          TweenMax.set($openMenu.find(".burguer:nth-child(3)"), {
            rotation: -40,
            delay: 0.85,
          });
        },
      });

      if (
        $_body.hasClass("archive-memes") ||
        $_body.hasClass("legado") ||
        $_body.hasClass("legal") ||
        $_body.hasClass("covid")
      ) {
        $openMenu.addClass("go-light");
      }

      TweenMax.set($animeBg, {
        x: "0%",
        onComplete: function () {
          if ($_body.hasClass("home")) {
            $.doTimeout(800, function () {
              $openMenu.addClass("go-dark");
            });
          } else if (
            $_body.hasClass("archive-memes") ||
            $_body.hasClass("legado") ||
            $_body.hasClass("legal") ||
            $_body.hasClass("covid")
          ) {
            $.doTimeout(800, function () {
              $openMenu.removeClass("go-light");
            });
          }

          TweenMax.set($animeBg, {
            x: "100%",
            delay: 0.8,
            onComplete: function () {
              TweenMax.set($animeBg, {
                autoAlpha: 0,
                delay: 0.8,
              });
            },
          });
          TweenMax.set([$mainNavigation, $subNavigation], {
            autoAlpha: 1,
            delay: 0.8,
          });
          TweenMax.set($outsideBg, {
            autoAlpha: 0.9,
            delay: 0.8,
          });
          TweenMax.to($outsideOverlay, 0.5, {
            autoAlpha: 1,
            delay: 0.8,
          });
        },
      });
    } else {
      _customScroll.registerEvents(/scroll/, /wheel/, /touchstart/);

      TweenMax.staggerTo(
        $textOpen.find(".char"),
        1.4,
        {
          y: "0%",
          autoAlpha: 1,
          delay: 0.25,
          ease: Expo.easeOut,
        },
        0.1
      );

      TweenMax.staggerTo(
        $textClose.find(".char"),
        1.4,
        {
          y: "-100%",
          autoAlpha: 0,
          ease: Expo.easeOut,
          onComplete: function () {
            TweenMax.set($textClose, {
              clearProps: "autoAlpha",
            });
          },
        },
        0.1
      );

      TweenMax.set(
        [
          $openMenu.find(".burguer:nth-child(1)"),
          $openMenu.find(".burguer:nth-child(3)"),
        ],
        {
          rotation: 0,
          onComplete: function () {
            TweenMax.set(
              [
                $openMenu.find(".burguer:nth-child(1)"),
                $openMenu.find(".burguer:nth-child(3)"),
              ],
              {
                y: 0,
                delay: 0.85,
                onComplete: function () {
                  TweenMax.set($openMenu.find(".burguer"), {
                    clearProps: "all",
                  });
                },
              }
            );
            TweenMax.set($openMenu.find(".burguer:nth-child(2)"), {
              autoAlpha: 1,
              delay: 0.85,
            });
          },
        }
      );

      if ($_body.hasClass("home")) {
        $.doTimeout(200, function () {
          $openMenu.removeClass("go-dark");
        });
      } else if (
        $_body.hasClass("archive-memes") ||
        $_body.hasClass("legado") ||
        $_body.hasClass("legal") ||
        $_body.hasClass("covid")
      ) {
        // $.doTimeout(300, function() {
        //   $openMenu.addClass("go-light");
        //   $.doTimeout(950, function() {
        //     $openMenu.removeClass("go-light");
        //   });
        // });
      }

      TweenMax.to($outsideOverlay, 0.5, {
        autoAlpha: 0,
      });

      TweenMax.set($animeBg, {
        x: "-100%",
        onComplete: function () {
          TweenMax.set($animeBg, {
            autoAlpha: 1,
            delay: 0.8,
          });
        },
      });

      $.doTimeout(900, function () {
        $openMenu.removeClass("js-open");
      });
      TweenMax.to([$mainNavigation, $subNavigation], 0.15, {
        autoAlpha: 0,
        onComplete: function () {
          TweenMax.set([$mainNavigation, $subNavigation], {
            clearProps: "autoAlpha",
          });
        },
      });
      TweenMax.set($outsideBg, {
        x: "-100%",
      });

      TweenMax.set($outsideBg, {
        clearProps: "all",
        delay: 0.9,
      });
    }
  });

  $outsideOverlay.on("click", function () {
    $openMenu.click();
  });
} // end function

/*******************************************************************************************
 ****                                                                                   ****
    =DOCUMENT =READY =START Document ready
 ****                                                                                   ****
*********************************************************************************************/
$(document).ready(function () {
  //** =Global objects
  (domainSite = window.location.host),
    ($_window = $(window)),
    ($_body = $("body")),
    ($_html = $("html")),
    ($_scrollContentWrapper = $(".scroll-content-wrapper")),
    ($_mainPage = $(".page-main")),
    ($_btn_nav_main = $(".btn-nav-main"));

  ($_currentPage = $(".page-main.page-current")),
    ($_toPreload = $(".preload")),
    ($_loadingPage = $("#loading-page")),
    ($_loadingPageLegado = $("#loading-page-legado"));

  if (domainSite == "velhotes.local") appIdNumber = "208754199655464";
  else appIdNumber = "382245758878166";

  // console.log(appIdNumber, domainSite);

  window.fbAsyncInit = function () {
    FB.init({
      appId: appIdNumber,
      autoLogAppEvents: true,
      cookie: true,
      xfbml: true,
      version: "v2.10",
    });
    FB.AppEvents.logPageView();
  };
  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "//connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");

  //** =Global variables
  getKeywords();
  calculateGlobalValues();
  _global_allowNavigate = false; //When loading do not allow clicks by user ( onStartPage revers to true)
  _server_hostname = window.location.hostname;

  //Scroll Global Variables
  _customScroll = null;
  _scrollTopMobileSafariFix = null;
  _scrollY = 0;
  _lastScroll = -1;
  _scrollDirection = "down";
  _blockAll = false;

  _page_scroll_listener = null;

  if (
    $_scrollContentWrapper.attr("data-scrollbar") != undefined &&
    !$_scrollContentWrapper.hasClass("js-scroll")
  ) {
    _customScroll = Scrollbar.init($_scrollContentWrapper[0], {
      speed: 0.8,
      syncCallbacks: true,
    });
    $_scrollContentWrapper.addClass("js-scroll");
  }

  _customScroll.unregisterEvents(/scroll/, /wheel/, /touchstart/);

  // if($_body.hasClass("mobile")) {
  //   $(".scroll-content-wrapper").removeAttr("data-scrollbar");
  //   _customScroll.destroy();
  //   _customScroll = null;
  // }

  // Request Animation Frame
  _rAF_loop =
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    // IE Fallback, you can even fallback to onscroll
    function (callback) {
      window.setTimeout(callback, 1000 / 60);
    };

  _raf_loop_id = null;

  //MainAnimationLoop
  initGlobalAnimations();

  //** =START PAGES
  onStartPageWhenRefresh(true);
  mainNavigation();
  callAsyncFunctions();

  if (!$_body.hasClass("home")) {
    if (cookie.get("age-check") != undefined) {
      if (cookie.get("age-check") == "true") {
        $(".ink-mask").hide();
      }
    } else {
      cookie.set("age-check", false);
    }
  }

  if (
    $_body.hasClass("covid") ||
    $_body.hasClass("legal") ||
    $_body.hasClass("")
  ) {
    $(".ink-mask").hide();
  }

  $("#yes").on("click", function () {
    cookie.set("age-check", true);
  });

  // VW/VH Unis fix for IOS
  window.viewportUnitsBuggyfill.init();

  //Start Plugins
  FastClick.attach(document.body); //no 300ms tap delay!

  /** -----------------------------------------------------------------------------------------
  =LOAD SECTIONS - triger events =CLICK to LOAD PAGE
  body class="js-no-ajax ismobile" > inserida via php == no nosso caso a mobile
  --------------------------------------------------------------------------------------------*/
  var domainSite = window.location.host;
  var mainTitle = " | Velhotes",
    homeTitle = $("a").attr("data-title-home");
  _forPopstate = true;
  $_linkInternal = $('a[href*="' + domainSite + '"]');

  $(document).on("click", 'a[data-remote="true"]', function () {
    var $this = $(this);

    // **ALLOW user load other pages only after page is loaded ( onStartPage )
    if (!_global_allowNavigate) return false;
    _global_allowNavigate = false;

    // exit and have normal click
    if (
      ($_html.hasClass("mobile") && !$this.hasClass("create-meme-modal")) ||
      $_body.hasClass("covid") ||
      $_body.hasClass("cocktails")
    )
      return true;
    if (
      $this.hasClass("modal") ||
      $this.hasClass("js-no-transPage") ||
      $_html.hasClass("ie") ||
      $_html.hasClass("edge")
    )
      return;

    //
    var thisHref = $this.attr("href"),
      thisHrefAjax = $this.attr("data-ajaxUrl"),
      thisTitle = $this.attr("data-title"),
      pageTransition = $this.attr("data-pageTrans"),
      forHistory = thisHref,
      newContent = thisHrefAjax,
      forTitle = thisTitle + mainTitle;

    //home page
    if (!thisTitle) {
      forTitle = homeTitle;
    }

    //for history
    if (_forPopstate) {
      history.pushState({}, forTitle, forHistory);
    }
    _forPopstate = true;

    // for title
    $("head title").html(forTitle);

    //ga('send', 'pageview', window.location.pathname); //analytics

    loadPages(newContent, pageTransition);

    return false;
  }); //end click

  /// HISTORY
  //  note: Chrome and Safari will fire a popstate event when the page loads but Firefox doesnt. When your page loads, it might have a non-null state object and the page will receive an onload event, but no popstate event. (window.history.state; on refresh page)
  if (window.addEventListener) {
    window.addEventListener("popstate", function (e) {
      if ($_html.hasClass("mobile")) return false;
      if (!e.state) {
        _forPopstate = true;
        return false;
      } // firefox vs webkit and safari trigers on
      _forPopstate = false;
      window.location = window.location; // reload page for this new adress!
      return false;
    });
  } //endif: does not excute for <= IE8

  /* -------------------------------------------------------------------------------------------
    =EVENTS DEFAULT BURO
    -------------------------------------------------------------------------------------------- */
  //OPEN NEW WINDOW
  $(document).on("click", "a[rel=external]", function (event) {
    event.stopImmediatePropagation();
    event.preventDefault();
    event.stopPropagation();
    var linkExterno = window.open($(this).attr("href"));
    return linkExterno.closed;
  });
  //PRINT
  $("a[rel=print]").click(function () {
    var imprimir = window.print();
    return false;
  });
  //E-MAIL: has classclass="email", e [-at-]
  $("a.email").each(function () {
    var mailReal = $(this).text().replace("[-at-]", "@");
    $(this).text(mailReal);
    $(this).attr("href", "mailto:" + mailReal);
  });
  //CLEAR FORMS
  $(
    "input[type=text], input[type=email], input.text, input.email, textarea"
  ).each(function () {
    if (!$(this).hasClass("txtClear")) return;
    var defeito = this.defaultValue;
    $(this).focus(function () {
      if ($(this).val() == defeito) {
        $(this).val("");
      }
    });
    $(this).blur(function () {
      if ($(this).val() == "") {
        $(this).val(defeito);
      }
    });
  });
  //OPEN POPUP
  $(document).on("click", ".newWindow", function () {
    event.stopImmediatePropagation();
    event.preventDefault();
    event.stopPropagation();
    var newwindow = window.open(
      $(this).attr("href"),
      "",
      "height=480,width=560"
    );
    if (window.focus) {
      newwindow.focus();
    }
    return false;
  });

  /*-------------------------------------------------------------------------------------------
    =KEYS
    --------------------------------------------------------------------------------------------*/
  $(document).on("keydown", function (event) {
    switch (event.which) {
      case 40: //down
        //return false;
        break;
      case 38: //up
        // return false;
        break;
      case 39: //next
        if ($_body.hasClass("single-memes")) {
          $(".next-meme").click();
        }
        // return false;
        break;
      case 37: //prev
        if ($_body.hasClass("single-memes")) {
          $(".prev-meme").click();
        }
        // return false;
        break;
      case 27: //close (esc)
        if ($_body.hasClass("js-modal-open"))
          $(".close-lightbox.js-active").click();
        if ($_body.hasClass("single-memes")) $(".close-single-meme").click();
      //break
      default:
        break;
    } //end switch
  }); //end keypress

  /*-------------------------------------------------------------------------------------------
    =RESIZE on
    --------------------------------------------------------------------------------------------*/
  $_window.on(
    "resize",
    $.debounce(500, function () {
      //** =recalculate global variables
      calculateGlobalValues();

      if ($_html.hasClass("no-object-fit")) {
        var $element = $(".page-current .element-cover");
        resizeLikeCover($element);
      }
      $(".create-new-wrapper").css(
        "height",
        $(".meme-grid .meme-container").outerHeight() -
          $(".meme-grid .meme-container > .author").outerHeight()
      );
    })
  ); //end resize window

  /////////////////////////
}); //END LOAD DOCUMENT

/********************************************************************************************
 **                                                                                       **
     =FORMS AJAX SUBMITS
 **                                                                                       **
*********************************************************************************************/

function submitJSON(action, value, onSuccess, onError) {
  // console.log(value);
  $.ajax({
    data: { action: action, value: value },
    type: "post",
    dataType: "json",
    url: "//" + _server_hostname + "/wp-admin/admin-ajax.php",
    success: function (data) {
      onSuccess(data);
      //console.log("success", data);
    },
    error: function (data) {
      onError(data);
      //console.log("error", data);
    },
  });
}

function submitForm($form) {
  if (validateForm($form) && !$form.hasClass("sending")) {
    $form.addClass("sending");

    $.ajax({
      data: $form.serialize(),
      type: "post",
      dataType: "json",
      url: "//" + _server_hostname + "/wp-admin/admin-ajax.php",
      success: function (data) {
        $form.removeClass("sending");
      },
      error: function (data) {
        $form.removeClass("sending");
      },
    });
  }
}

$("#subscribe-form").on("submit", function (event) {
  var $form = $(this),
    $feedbackContainer = $(".feedback-container"),
    feedBackHeight = $feedbackContainer.find("h3").height();

  event.stopImmediatePropagation();
  event.preventDefault();
  event.stopPropagation();

  if ($form.find(".email").val().length === 0) return;

  $.ajax({
    type: "post",
    url: "/mailchimp.php",
    data: $form.serialize(),
    success: function (data) {
      TweenMax.to(".subscribe-feedback", 0.6, {
        y: feedBackHeight,
        ease: Expo.easeOut,
        force3D: true,
      });
      TweenMax.to(".positive-feedback", 0.6, {
        y: 0,
        ease: Expo.easeOut,
        force3D: true,
        delay: 0.2,
      });
      TweenMax.to("button[type=submit]", 1, {
        backgroundColor: "#30EC66",
        ease: Expo.easeOut,
      });
      TweenMax.to("button[type=submit] svg", 0.4, {
        rotation: 90,
        ease: Expo.easeOut,
        onComplete: function () {
          $.doTimeout(1500, function () {
            $("form")[0].reset();
            TweenMax.to("button[type=submit]", 1, {
              backgroundColor: "#e7d2aa",
              ease: Expo.easeOut,
            });
            TweenMax.to("button[type=submit] svg", 0.4, {
              rotation: 0,
              ease: Expo.easeOut,
            });
            TweenMax.to(".positive-feedback", 0.6, {
              y: -feedBackHeight,
              force3D: true,
              ease: Expo.easeOut,
            });
            TweenMax.to(".subscribe-feedback", 0.6, {
              y: 0,
              force3D: true,
              delay: 0.2,
              ease: Expo.easeOut,
            });
          });
        },
      });
    },
    error: function (data) {
      TweenMax.to("button[type=submit]", 1, {
        backgroundColor: "#EC3B30",
        ease: Expo.easeOut,
        onComplete: function () {
          $.doTimeout(1500, function () {
            $("form")[0].reset();
            TweenMax.to("button[type=submit]", 1, {
              backgroundColor: "#e7d2aa",
              ease: Expo.easeOut,
            });
          });
        },
      });
    },
  });
});

/*******************************************************************************************
 **                                                                                       **
    =GENERAL FUNCTIONS AND PLUGINGS CONTROL
 **                                                                                       **
*********************************************************************************************/
/** =Global page values */
function calculateGlobalValues() {
  _globalViewportW = verge.viewportW();
  _globalViewportH = verge.viewportH();
  _globalHalfViewportH = (_globalViewportH / 2).toFixed(0);
}

/** =ASYNC PLUGINS (polyfill, etc) */
function callAsyncFunctions() {
  if (window.respimage) respimage();
}

/** =Random integer between min (included) and max (excluded) */
function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

/** =Manage for added body and page classes */
function manageBodyClasses() {
  if ($_body.hasClass("js-no-ajax")) {
    $_body.addClass(
      $(".page-main.page-current .page-toload").attr("data-bodyClass")
    );
  } else {
    $_body.removeClass(
      $(".page-main.page-current .page-toload").attr("data-bodyClass")
    );
    $_body.addClass(
      $(".page-main.page-next .page-toload").attr("data-bodyClass")
    );
  }
}
