function memePage(do_meme) {
  if (!do_meme) {
	 $_window.off("scroll.memePage");
   $_window.off("resize.memePage");
	 return false;
  }
  else {

    if(_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      _scrollRef = function() { _raf_loop_id = _rAF_loop( memePage_scroll_rAF ); }
      $_window[0].addEventListener("scroll", _scrollRef, { passive: true } );
    }
    else {

      _page_scroll_listener = function(status) {
        memePage_scroll_rAF(status);
      };

      _customScroll.addListener(_page_scroll_listener);
    }
  }

  // _customScroll.unregisterEvents(/scroll/, /wheel/, /touchstart/);

  /*variables*/
  if(!sessionStorage.login) {
    sessionStorage.login = JSON.stringify({
      "is_logged" : false,
      "is_confirmed" : false
    });
  }

  var flag = false,
      getMoreFlag = false,
      login_info = JSON.parse(sessionStorage.login),
      url = '',
      dataId = '',
      in_speed = .5,
      out_speed = .2,
      can_animate = false,
      headerAnim = new TimelineMax();


  /*DOM variables*/
  var $velhotesLightboxes = $(".velhotes-lightboxes"),
      $offMenu = $(".offmenu"),
      $mainNavigation = $offMenu.find(".main-navigation"),
      $lightMemeBox = $(".create-meme-lightbox"),
      $headerMain = $("#header-main"),
      $headerGlobal = $("#header-main .header-global"),
      $memeGrid = $(".meme-grid"),
      $memeItem = $(".meme-wrapper"),
      $createMemeBtn = $("#create-meme-btn"),
      $createMemeBtnA = $("#create-meme-btn").parent('a'),
      $memeForm = $('.meme-generator-form'),
      $loginModal = $(".login-lightbox"),
      $continueLoginModal = $(".continue-login-lightbox"),
      $closeLogin = $(".close-login"),
      $closeContinueLogin = $(".close-continue-login"),
      $closeSingleMeme = $(".close-single-meme"),
      $pageFooter = $('.page-footer'),
      $memeForm = $('.meme-generator-form'),
      $singleMemeLightbox = $(".single-meme-lightbox"),
      $yesBtn = $("#yes"),
      $mask = $('.ink-mask'),
      $inputForm = $memeForm.find("input[type='text']"),
      $filterUl = $(".filter-container ul"),
      $activeLabel = $(".active-label"),
      $filterWrapper = $(".filter-wrapper"),
      $closeCreateMeme = $(".close-create-meme"),
      $contentMainWrapper = $(".content-main-wrapper"),
      $memeGrid = $(".meme-grid"),
      $facebookShareBtn = $(".facebook-share-btn"),
      filterFlag = ' ',
      filter = ' ',
      $memeLinkVelhotes = $("#memes-velhotes"),
      $memeLinkCria = $("#cria-o-meme"),
      $homePageLink = $(".homepage-link h2"),
      $createMemeSecBtn = $(".create-meme-btn"),
      $userImage = $(".user-image"),
      $sortByBtn = $(".sort-by-btn"),
      $userContainerMobile = $(".user-container.mobile"),
      $filterContainerMobile = $(".filter-container.mobile"),
      $menuBurguerOpen = $(".offmenu-btn .text.open"),
      $menuBurguerClose = $(".offmenu-btn .text.close"),
      $velhotesLogo = $(".velhotes-logo"),
      $openMenu = $(".open-menu");


  if (!$_body.hasClass("mobile")) {
    $(".create-new-wrapper").css("height", $(".meme-grid .meme-container").outerHeight());
  }

  if(!$mainNavigation.find("li").eq(0).hasClass("active")) {
    $mainNavigation.find("li").removeClass("active");
    $mainNavigation.find("li").eq(0).addClass("active");
  }

  if($_body.hasClass("single-memes")){
    $_body.append($(".single-meme-lightbox").detach());

    TweenMax.fromTo($(".single-meme-lightbox"), .5, {
      autoAlpha: 0,
      scale: .98
    }, {
      autoAlpha: 1,
      scale: 1,
      delay: .5,
      ease: Expo.easeInOut
    })
  }

  $memeForm.find('input').on('keyup focusout', function(e) {

    if(e.type == 'keyup'){
      checkKeywords($(this));
     }
     else if(e.type == 'focusout'){

      checkKeywords($(this));
     }
  });

  if(!$mainNavigation.find("li").eq(2).hasClass("active")) {
    $mainNavigation.find("li").removeClass("active");
    $mainNavigation.find("li").eq(2).addClass("active");
  }

  /*inits*/
  breakTitleLetters($homePageLink);
  breakTitleLetters($menuBurguerOpen);
  breakTitleLetters($menuBurguerClose);
  TweenMax.staggerTo($memeLinkCria.find(".char"), 1.4, {y: 0, autoAlpha: 1, delay: 0.1, ease: Expo.easeOut}, 0.1);
  TweenMax.staggerTo($memeLinkVelhotes.find(".char"), 0.4, {y: 100, autoAlpha: 1, ease: Expo.easeIn}, 0.1);


  setupLoginFlow();

  createMeme();

  $(".corda").css("top", - (($(".create-meme-lightbox .create-meme-wrapper .create-meme-container .image-wrapper").height() - $("#meme-result").height()) / 2) - 60 + "px");

  if(!$_body.hasClass("mobile")) {
    $(".continue-login-lightbox .logout-btn").css("width", $(".continue-login-lightbox .user-image").width());
  }

  $createMemeBtnA.addClass("create-meme-modal");
  $velhotesLogo.off('click');

  // $velhotesLogo.on('click', function(){    
  //   // $.doTimeout(1000, function(){
  //   //   TweenMax.set($memeLinkVelhotes, {autoAlpha: 1});
  //   //   TweenMax.set($memeLinkCria, {autoAlpha: 1});
  //   //   TweenMax.staggerTo($memeLinkCria.find(".char"), 0.4, {y: 100, autoAlpha: 1, ease: Expo.easeOut}, 0.1);
  //   //   TweenMax.staggerTo($memeLinkVelhotes.find(".char"), 1.4, {y: 0, delay: 0.1, autoAlpha: 1, ease: Expo.easeIn}, 0.1);
  //   // })    
  // })

  $memeGrid.imagesLoaded(function(){
    TweenMax.staggerTo($memeItem, 0.6, {scale: 1, autoAlpha: 1, ease:Expo.easeOut}, 0.1);
  });

  $.doTimeout(0, function() {
    checkLoginState();
  });

  /*events*/
  if($_body.hasClass("phone")){
    $userImage.on("click", function() {
      var $this = $(this);

      if(!$("#header-main").hasClass("js-filter-open")) {
        $headerMain.toggleClass("js-user-open");

        if($headerMain.hasClass("js-user-open")) {
          TweenMax.to($userContainerMobile, out_speed, {
            autoAlpha: 1
          });

          TweenMax.to($(".create-meme-link"), out_speed, {
            y: $userContainerMobile.outerHeight(),
            ease: Expo.easeInOut
          });
        } else {
          TweenMax.to($userContainerMobile, out_speed, {
            autoAlpha: 0
          });
          TweenMax.to($(".create-meme-link"), out_speed, {
            y: 0,
            ease: Expo.easeInOut
          });
        }
      } else {
        TweenMax.to($filterContainerMobile, out_speed, {
          autoAlpha: 0
        });
        TweenMax.to($(".create-meme-link"), out_speed, {
          y: 0,
          ease: Expo.easeInOut,
          onComplete: function () {
            $headerMain.addClass("js-user-open");
            $headerMain.removeClass("js-filter-open");

            TweenMax.to($userContainerMobile, out_speed, {
              autoAlpha: 1
            });

            TweenMax.to($(".create-meme-link"), out_speed, {
              y: $userContainerMobile.outerHeight(),
              ease: Expo.easeInOut
            });
          }
        });
      }
    });

    $sortByBtn.on("click", function() {
      var $this = $(this);
      // TweenMax.set($createMemeBtn, {autoAlpha:1});
      // $("#create-meme-btn").css("display", "flex");

      if(!$("#header-main").hasClass("js-user-open")) {
        $headerMain.toggleClass("js-filter-open");

        if($headerMain.hasClass("js-filter-open")) {
          TweenMax.to($filterContainerMobile, out_speed, {
            autoAlpha: 1
          });

          TweenMax.to($(".create-meme-link"), out_speed, {
            y: $filterContainerMobile.outerHeight() - 15,
            ease: Expo.easeInOut
          });
        } else {
          TweenMax.to($filterContainerMobile, out_speed, {
            autoAlpha: 0
          });
          TweenMax.to($(".create-meme-link"), out_speed, {
            y: 0,
            ease: Expo.easeInOut
          });
        }
      } else {
        TweenMax.to($userContainerMobile, out_speed, {
          autoAlpha: 0
        });
        TweenMax.to($(".create-meme-link"), out_speed, {
          y: 0,
          ease: Expo.easeInOut,
          onComplete: function () {
            $headerMain.removeClass("js-user-open");
            $headerMain.addClass("js-filter-open");

            TweenMax.to($filterContainerMobile, out_speed, {
              autoAlpha: 1
            });

            TweenMax.to($(".create-meme-link"), out_speed, {
              y: $filterContainerMobile.outerHeight(),
              ease: Expo.easeInOut
            });
          }
        });
      }
    });
  }

  $_toPreload.imagesLoaded(function($images, $proper, $broken) {
    var fPreload = $(this).imagesLoaded();
    fPreload.always(function() {

      TweenMax.staggerFromTo($memeGrid.find(".meme-container"), 1, {
        autoAlpha: 0,
        scale: .98
      },{
        autoAlpha: 1,
        scale: 1
      }, .1)
    });
  });


  if (cookie.get('age-check') == 'true') {
    TweenMax.set($(".create-meme-link"), {autoAlpha: 1});
    TweenMax.set($(".sort-by-btn"), {autoAlpha: 1});
    TweenMax.set($memeLinkCria, {autoAlpha: 1});
    TweenMax.set($("#create-meme-btn").parent().parent(), {autoAlpha:1});
    TweenMax.set("#create-meme-btn", {autoAlpha:1});
    TweenMax.set($openMenu, {autoAlpha:1});
    TweenMax.staggerTo($openMenu.find(".text.open .char"), 1.4, {y: 0, autoAlpha: 1, ease: Expo.easeOut}, 0.1);
    TweenMax.staggerTo($openMenu.find(".burguer"), 1.4, {
      x: "0%",
      autoAlpha: 1,
      ease: Expo.easeOut,
      onComplete: function() {
        $openMenu.find(".burguer-wrapper").removeClass("hidden");
        TweenMax.set($openMenu.find(".burguer"), {clearProps: "all"});
        $openMenu.css("pointer-events", "visible");
      }
    }, 0.1);
  }

  if(!cookie.get('age-check'))
    _customScroll.unregisterEvents(/scroll/, /wheel/, /touchstart/);

  $yesBtn.on("click", function() {
    $mask.addClass("in");
    TweenMax.set($("#create-meme-btn").parent().parent(), {autoAlpha:1});
    if(!$_body.hasClass("phone"))
      if (!$_html.hasClass("ie")) {
        TweenMax.to($headerMain.find(".velhotes-logo"), 0.8, {y: 0, force3D: true, ease: Expo.easeOut});
        TweenMax.to($headerMain.find(".header-global"), 0.8, {y: 0, force3D: true, ease: Expo.easeOut});
      }
    $("#header-main").addClass("active");
    $("#header-main .letters").addClass("add-transition");

    TweenMax.to($(".velhotes-logo"), 0.8, {y: 0, force3D: true, delay: .5, ease: Expo.easeOut});

    $.doTimeout(100, function(){
      TweenMax.to(".velhotes-logo", 1, {y: 0, ease: Expo.easeOut});
      if ($_html.hasClass("ie")) {
        TweenMax.to(".velhotes-logo svg", 1, {scale: 1.4, autoAlpha: 1, ease: Expo.easeOut});
      } else {
        TweenMax.to(".velhotes-logo svg", 1, {scale: 1, autoAlpha: 1, ease: Expo.easeOut});
      }
      breakTitleLetters($memeLinkCria);
      TweenMax.set("#create-meme-btn", {autoAlpha:1});
      _customScroll.registerEvents(/scroll/, /wheel/, /touchstart/);
    });

    $.doTimeout(500, function(){
      if($_body.hasClass("phone")) {
        TweenMax.set($(".create-meme-link"), {autoAlpha: 1});
        TweenMax.set($(".sort-by-btn"), {autoAlpha: 1});
      }

      TweenMax.set($memeLinkCria, {autoAlpha: 1});
      TweenMax.staggerTo($("#cria-o-meme").find(".char"), 1.4, {y: 0, autoAlpha: 1, ease: Expo.easeOut}, 0.1);

      TweenMax.set($openMenu, {autoAlpha:1});
      TweenMax.staggerTo($openMenu.find(".text.open .char"), 1.4, {y: 0, autoAlpha: 1, ease: Expo.easeOut}, 0.1);
      TweenMax.staggerTo($openMenu.find(".burguer"), 1.4, {
        x: "0%",
        autoAlpha: 1,
        ease: Expo.easeOut,
        onComplete: function() {
          $openMenu.find(".burguer-wrapper").removeClass("hidden");
          TweenMax.set($openMenu.find(".burguer"), {clearProps: "all"});
          $openMenu.css("pointer-events", "visible");
        }
      }, 0.1);
    })

    $.doTimeout(900, function(){
        $("#smile-container").addClass("openSmile");
        $("#create-meme-btn").addClass("openFace");
    })
    $.doTimeout(1300, function() {
        TweenMax.to("#smile-container #wink-container span:first-of-type", 0.6, {rotation: 35, force3D: true, ease: Expo.easeOut});
        TweenMax.to("#smile-container #wink-container span:last-of-type", 0.6, {rotation: -35, force3D: true, ease: Expo.easeOut});
    })

    $.doTimeout(2000, function() {
      $mask.hide();
      _customScroll.registerEvents(/scroll/, /wheel/, /touchstart/);
    });
  });

  $_window.on("resize.memePage", $.debounce(500,memePage_resize));

  $(document).on("click",".close-single-meme", function() {
    var $this = $(this);

    history.pushState({}, $this.attr("data-title"), $this.attr("data-targetUrl"));

    $('head title').html($this.attr("data-title"));

    $_body.removeClass("single-memes").addClass("archive-memes");

    $(".single-meme-lightbox").remove();

    TweenMax.to($(".single-meme-lightbox"), out_speed, {
      autoAlpha: 0,
      scale: .98,
      ease: Expo.easeInOut
    });
  });

  $(document).on("click", ".create-meme-btn", function() {
    $createMemeBtnA.click();
  });

  $(".outside-hitzone").on("click", function(event) {
    $(".close-lightbox.js-active").click();
  });

  $(document).on("click",".single-meme-outside-hitzone", function(event) {
    $(".close-single-meme").click();
  });

  $(document).on("click",".denunciar-meme", function() {
    if(!$_body.hasClass("phone")) {
      TweenMax.to($(".single-denuncia-lightbox"), .1, {
        autoAlpha: 1
      });
      TweenMax.to($(".close-single-meme"), 1, {scale:0, ease: Expo.easeOut});
    } else {
      TweenMax.set($(".single-denuncia-lightbox"), {
        top: $(".single-denuncia-lightbox").parent().scrollTop(),
        onComplete: function() {
          $(".single-meme-lightbox").css("overflow", "hidden");
          TweenMax.to($(".single-denuncia-lightbox"), .1, {
            autoAlpha: 1
          });
          TweenMax.to($(".close-single-meme"), 1, {scale:0, ease: Expo.easeOut});
        }
      });
    }
  });

  $(document).on("click",".close-denuncia", function() {
    TweenMax.to($(".single-denuncia-lightbox"), .1, {
      autoAlpha: 0
    });
    TweenMax.to($(".close-single-meme"), 1, {scale:1, ease: Expo.easeOut});
    if($_body.hasClass("phone")) {
      TweenMax.set($(".single-denuncia-lightbox"), {
        clearProps: "top"
      });
      $(".single-meme-lightbox").css("overflow", "");
    }
  });

  $(document).on("click",".send-denuncia", function() {
    var response = {};

    response.url = window.location.href;

    TweenMax.to($(".lds-eclipse"), .5, { opacity: 1 });



    submitJSON("reportMeme", response, function(data) {

      //Success
    }, function(data) {

      //console.log("success" + data);

      $(".close-denuncia").click();
      TweenMax.to($(".lds-eclipse"), .5, { opacity: 0 });
      //Success
    }, function(data) {
      //console.log("error" + data);

      $(".close-denuncia").click();
      TweenMax.to($(".lds-eclipse"), .5, { opacity: 0 });
      //Error
    });
  })

  if(!$_body.hasClass("phone")) {
    // $(".filter-container").css("width", $(".filter-container").find("h2").width() + $(".filter-wrapper").outerWidth() - 15 + "px");

    TweenMax.fromTo($(".filter-container"), 1, {
      y: 40,
      autoAlpha: 0
    },{
      y: 0,
      autoAlpha: 1,
      ease: Expo.easeOut
    });
  }

  $activeLabel.on("click", function(){
    if(!$_body.hasClass("phone")) {
      if (!can_animate) {
        $filterWrapper.addClass("add-shadow");
        TweenMax.to($filterWrapper, 0.6, {zIndex: 10, height: $filterUl.find("li").outerHeight(true) * $filterUl.find("li").length + 90, className:"+=open", ease: Expo.easeOut, onComplete:function(){
          can_animate = true;
        }});
      } else{
        TweenMax.to($filterWrapper, 0, {zIndex: 4, height: 70, className:"-=open"});
        $filterWrapper.removeClass("add-shadow");
        can_animate = false;
      }
    }
  });

  $filterUl.find("li").on("click", function(){
    var $this = $(this),
        val = $this.text();

    $filterUl.find("li").removeClass("selected");
    $this.addClass("selected");

    if(!$_body.hasClass("phone")) {
      $activeLabel.text(val);

      TweenMax.to($filterWrapper, 0, {zIndex: 4, height: 70, className:"-=open"});
      $filterWrapper.removeClass("add-shadow");
      can_animate = false;
    } else {
      $this.addClass("active-label");
    }
  });

  $contentMainWrapper.on("click", function(){
    if ($filterWrapper.hasClass("open")) {
      TweenMax.to($filterWrapper, 0, {zIndex: 4, height: 70, className:"-=open"});
      $filterWrapper.removeClass("add-shadow");
      can_animate = false;
    }
  });

  $filterWrapper.find('li').on('click', function(){
    filter = $(this).attr('data-filter');
    filterFunction(filter);
  })

  $('.share-on-facebook').on('click', function(){
  //   if($(this).find('polyline').hasClass('checked')){
  //     $(this).find('polyline').removeClass('checked');
  //     $(this).find('polyline').addClass('non-checked');
  //   }
  //   else{
  //     $(this).find('polyline').removeClass('non-checked');
  //     $(this).find('polyline').addClass('checked');
  //   }

  })
  /*functions*/

  function createMeme() {
    var is_submiting = false;
    $inputForm.on('keyup', checkVal);
    $('#fb-check').prop('checked','true');
    $inputForm.blur(function() {
      var $this = $(this);
      if(!$.trim($this.val()).length){
        $this.addClass("no-value");
        $(".save-meme").addClass("off");
        $(".char-count").html("40");
      }
    });

    $(document).on('input', '#antonio', function() {
      var $this = $(this);
      var $name = $this.attr("name");
      var prev = $("#"+$name+"-chars").html();

      $("#"+$name+"-chars").html(40 - $this.val().length);
    });

    $(document).on('input', '#alcino', function() {
      var $this = $(this);
      var $name = $this.attr("name");
      var prev = $("#"+$name+"-chars").html();

      $("#"+$name+"-chars").html(40 - $this.val().length);
    });


    function checkVal(){
      var $this = $(this);
      if ($this.val().length > 0){
        if ($this.hasClass("no-value")) {
          $this.removeClass("no-value");
        }
      }
      else {
        $this.addClass("no-value");
        $(".save-meme").addClass("off");
      }

      if (!$inputForm.hasClass("no-value")) {
        $(".save-meme").removeClass("off");
      }
    }

    var generate = document.getElementById('shareMeme');

    generate.addEventListener('click', function() {

      if(is_submiting) return;

      is_submiting = true;

      checkKeywords('', 'submit');

      if(flag == true) {
        TweenMax.to($('#alert-lang'), 1, {
          autoAlpha: 1
        });

        flag = false;
        is_submiting = false;
      }
      else {
        response = JSON.parse(sessionStorage.response);


        response.antonio = $('#antonio').val();
        response.alcino = $('#alcino').val();
        response.image = canvas.toDataURL('image/jpeg');

        sessionStorage.response = JSON.stringify(response);

        TweenMax.to($(".lds-eclipse"), .5, { opacity: 1 });

        submitJSON("saveMeme", response, function(data) {

          $lightMemeBox.attr("data-url", data);

          TweenMax.to($lightMemeBox.find(".phase-1"), .1, {
            autoAlpha: 0,
            y: -40,
            ease: Expo.easeOut
          });
          TweenMax.fromTo($lightMemeBox.find(".phase-2"), 1,{
            y: -40,
            autoAlpha: 0
          },{
            autoAlpha: 1,
            y: 0,
            ease: Expo.easeOut
          });

          TweenMax.to($lightMemeBox.find(".save-meme"), 1.2, {
            scaleY: 0,
            transformOrigin: "bottom center",
            ease: Expo.easeOut
          });
          TweenMax.to($lightMemeBox.find(".save-meme span"), 1.2, {
            opacity: 0,
            y: -20,
            ease: Expo.easeOut
          });

          if(!$_body.hasClass("phone")) {
            TweenMax.to($lightMemeBox, 1, {
              y: $lightMemeBox.find(".save-meme").outerHeight()/2,
              delay: .2,
              ease: Expo.easeOut
            });
          } else {
            $(".create-meme-lightbox").scrollTop(0);
          }

          // if($(".share-on-facebook input").is(":checked")){
          //   console.log('TESTE');
          //   TweenMax.set($facebookShareBtn, { autoAlpha: 0 });
          //   $facebookShareBtn.click();
          // }

          is_submiting = false;
          $(".save-meme").addClass("off");
          $inputForm.val(' ');
          $(".char-count").html("40");
          $inputForm.addClass("no-value");
          $(".save-meme").addClass("off");
          $(".share-on-facebook input").prop('checked', false);
          // $(document).trigger('reset-meme-canvas');

          TweenMax.to($(".lds-eclipse"), .5, { opacity: 0 });

          $.get('/memes', function(data){
            if($(data).find('.meme-grid > *').length > 0){

              TweenMax.to($memeGrid.parent().find(".meme-grid"), .5, {
                opacity: 0,
                onComplete: function() {
                  $memeGrid.parent().find(".meme-grid").remove();
                  $(".content-main-wrapper").append($(data).find('.meme-grid'));
                  if (!$_body.hasClass("mobile")) {
                    $(".create-new-wrapper").css("height", $(".meme-grid .meme-container").outerHeight());
                  }
                  TweenMax.staggerTo($('.meme-container'), 0.4, {scale: 1, autoAlpha: 1, ease:Expo.easeOut}, 0.05);
                }
              });
            }
          });
            //Success
        }, function(data) {
          //Error

        });
      }
    });


    var canvas = document.getElementById('canvasMemeGenerator');
    var ctx = canvas.getContext('2d');

    var balloons = ['Advogado', 'Juiz'/*, 'Brindemos a isso'*/];

    $(document).on('keyup focusout change paste','#antonio', function(){
      balloons[0] = $('#antonio').val();
      onImageLoaded();
    })
    $(document).on('keyup focusout change paste','#alcino', function(){
      balloons[1] = $('#alcino').val();
      onImageLoaded();
    })

    $(document).on("reset-meme-canvas", function() {
      balloons[0] = 'Advogado';
      balloons[1] = 'Juiz';
      onImageLoaded();
    })

    var image = new Image();
    image.crossOrigin = 'Anonymous';
    image.onload = onImageLoaded.bind(this);
    image.src = '/public/imgs/velhotes-meme.jpg';

    function onImageLoaded() {

      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);


      if(balloons[0].length > 14 && balloons[0].length < 24) {
        setBalloon(balloons[0], 170, 360, 240, 32);
      }
      else if(balloons[0].length >= 24){
        setBalloon(balloons[0], 170, 350, 240, 32);
      } 
      else {
        setBalloon(balloons[0], 170, 405, 240, 32);
      }

      if(balloons[1].length > 12 && balloons[1].length < 22) {
        setBalloon(balloons[1], 690, 175, 220, 32);
      }
      else if(balloons[1].length >= 22){
        setBalloon(balloons[1], 690, 150, 220, 32);
      }
      else {
        setBalloon(balloons[1], 690, 225, 220, 32);
      }
    }

    function setBalloon(phrase, x, y, maxWidth, lineHeight) {
      ctx.fillStyle = 'white';
      ctx.font = '44px velhotes-roman';
      ctx.textAlign = 'center';
      ctx.lineHeight = '1.4';
      canvas.style.letterSpacing = '-2px';
      wrapText(ctx, phrase, x, y, maxWidth, lineHeight);
    }

    // http://www.html5canvastutorials.com/tutorials/html5-canvas-wrap-text-tutorial/
    function wrapText(context, text, x, y, maxWidth, lineHeight) {
      var words = text.split(' ');
      var line = '';

      for(var n = 0; n < words.length; n++) {
        var testLine = line + words[n] + ' ';
        var metrics = context.measureText(testLine);
        var testWidth = metrics.width;
        if (testWidth > maxWidth && n > 0) {
          context.fillText(line, x, y);
          line = words[n] + ' ';
          y += lineHeight;
        }
        else {
          line = testLine;
        }
      }
      context.fillText(line, x, y);
    }
  }

  function setupLoginFlow() {
    var $facebookBtn = $(".facebook-login-btn"),
        $facebookContinueBtn = $(".facebook-continue-login-btn"),
        $closeCreateMeme = $(".close-create-meme"),
        $logoutBtn = $(".logout-btn");

    $createMemeBtnA.on('click', function(event){
      event.stopImmediatePropagation();
      event.preventDefault();
      event.stopPropagation();
      $('#fb-check').prop('checked','true');
      // if($_html.hasClass("safari") && $_body.hasClass("mobile")) {
      //   lockBodySafari();
      // }

      TweenMax.to($velhotesLightboxes, in_speed, {
        autoAlpha: 1,
        ease: Expo.easeOut
      });

      $_body.addClass("js-modal-open");

      //verificar se estamos logados e agir de acordo
      if(login_info.is_logged) {

        if(!login_info.is_confirmed) {
          $(".close-lightbox").removeClass("js-active");
          $continueLoginModal.find(".close-lightbox").addClass("js-active");

          TweenMax.fromTo($continueLoginModal, in_speed, {
            autoAlpha: 0,
            scale: .98
          },{
            autoAlpha: 1,
            scale: 1,
            ease: Expo.easeOut
          });
        }
        else {
          $(".close-lightbox").removeClass("js-active");
          $lightMemeBox.find(".close-lightbox").addClass("js-active");

          TweenMax.fromTo($lightMemeBox, in_speed, {
            autoAlpha: 0,
            scale: .98
          },{
            autoAlpha: 1,
            scale: 1,
            ease: Expo.easeInOut
          });
        }
      }
      else {
        $(".close-lightbox").removeClass("js-active");
        $loginModal.find(".close-lightbox").addClass("js-active");

        TweenMax.fromTo($loginModal, in_speed, {
          autoAlpha: 0,
          scale: .98
        },{
          autoAlpha: 1,
          scale: 1,
          ease: Expo.easeInOut
        });
      }
    });

    $facebookShareBtn.on("click", function() {
      FB.ui({
        method: 'share',
        display: 'popup',
        href: $lightMemeBox.attr("data-url"),
      }, function(response){

        if (response && !response.error_message) {

          TweenMax.to($lightMemeBox.find(".create-meme-container "), .5, {
            scale: .98,
            ease: Expo.easeInOut
          });
          TweenMax.to($velhotesLightboxes, in_speed, {
            autoAlpha: 0,
            delay: .5,
            ease: Expo.easeOut
          });

          //shares(url, dataId);
        } else {
          // console.log('Error while posting.', response);
        }
      });


    });

    $closeCreateMeme.on('click', function(){
      $_body.removeClass("js-modal-open");

      TweenMax.to($lightMemeBox, out_speed, {
        autoAlpha: 0,
        scale: .98,
        ease: Expo.easeOut,
        onComplete: function() {
          $(document).trigger('reset-meme-canvas');
          TweenMax.set($lightMemeBox.find(".phase-2"), {
            autoAlpha: 0
          });
          TweenMax.set($lightMemeBox.find(".phase-1"), {
            autoAlpha: 1,
            y: 0
          });
          TweenMax.set($lightMemeBox.find(".save-meme"), {
            scaleY: 1
          });
          TweenMax.set($lightMemeBox.find(".save-meme span"), {
            opacity: 1,
            y: 0
          });
          TweenMax.set($lightMemeBox, {
            y: 0
          });
        }
      });

      TweenMax.to($velhotesLightboxes, in_speed, {
        autoAlpha: 0,
        delay: .1,
        ease: Expo.easeOut
      });

      $.doTimeout(500, function() {
        $inputForm.val(' ');
        $(".char-count").html("40");
        $inputForm.addClass("no-value");
        $(".save-meme").addClass("off");
        $(".share-on-facebook input").prop('checked', false);
      })


      // if($_html.hasClass("safari") && $_body.hasClass("mobile")) {
      //   unlockBodySafari();
      // }
    });

    $closeLogin.on("click", function() {
      $_body.removeClass("js-modal-open");

      TweenMax.fromTo($loginModal, out_speed, {
        autoAlpha: 1,
        scale: 1
      },{
        autoAlpha: 0,
        scale: .98,
        ease: Expo.easeInOut
      });

      TweenMax.to($velhotesLightboxes, in_speed, {
        autoAlpha: 0,
        delay: .1,
        ease: Expo.easeOut
      });

      // if($_html.hasClass("safari") && $_body.hasClass("mobile")) {
      //   unlockBodySafari();
      // }
    });

    $closeContinueLogin.on("click", function() {
      $_body.removeClass("js-modal-open");

      TweenMax.fromTo($continueLoginModal, out_speed, {
        autoAlpha: 1,
        scale: 1
      },{
        autoAlpha: 0,
        scale: .98,
        ease: Expo.easeInOut
      });

      TweenMax.to($velhotesLightboxes, in_speed, {
        autoAlpha: 0,
        delay: .1,
        ease: Expo.easeOut
      });

      // if($_html.hasClass("safari") && $_body.hasClass("mobile")) {
      //   unlockBodySafari();
      // }
    });

    $facebookBtn.on("click", function() {
      FB.login(function(response){
        $.doTimeout(500, function(){
          testAPI();
        })        
      }, {scope: 'email'});
    });


    $facebookContinueBtn.on("click", function() {
      $(".close-lightbox").removeClass("js-active");
      $lightMemeBox.find(".close-lightbox").addClass("js-active");

      response = JSON.parse(sessionStorage.response);
      submitJSON("saveProfile", response, function(data) {
        //Success
      }, function(data) {
        //Error
      });
      TweenMax.to($continueLoginModal.find(".login-container .row"), .2, {
        opacity: 0,
        ease: Expo.easeOut,
        onComplete: function() {
          var left_offset = ($lightMemeBox.find(".create-meme-container").outerWidth() - $continueLoginModal.find(".login-container").outerWidth())/2,
              top_offset = ($lightMemeBox.find(".create-meme-container").outerHeight() - $continueLoginModal.find(".login-container").outerHeight())/2;

          TweenMax.to($continueLoginModal.find(".login-container"), .5, {
            width: $lightMemeBox.find(".create-meme-container").width(),
            height: $lightMemeBox.find(".create-meme-container").height(),
            x: - left_offset,
            //y: - top_offset,
            ease: Expo.easeOut,
            onComplete: function() {
              TweenMax.set($lightMemeBox, { autoAlpha: 1 });


              TweenMax.set($lightMemeBox.find(".save-meme"), {
                scaleY: 0,
                transformOrigin: "bottom center"
              });
              TweenMax.set($lightMemeBox.find(".save-meme span"), {
                opacity: 0,
                y: 20
              });

              TweenMax.set($lightMemeBox.find(".image-wrapper"), { scaleY: 0 });
              TweenMax.set($lightMemeBox.find("#meme-result .block-bg-cover"), { rotationX: -120 });

              TweenMax.set([$lightMemeBox.find(".create-meme-text-wrapper h2"), $lightMemeBox.find(".create-meme-text-wrapper h3"), $lightMemeBox.find(".create-meme-text-wrapper h2"), $lightMemeBox.find(".create-meme-text-wrapper form")], {
                opacity: 0,
                y: -40
              });

              TweenMax.staggerTo([$lightMemeBox.find(".create-meme-text-wrapper h2"), $lightMemeBox.find(".create-meme-text-wrapper h3"), $lightMemeBox.find(".create-meme-text-wrapper h2"), $lightMemeBox.find(".create-meme-text-wrapper form")], 2, {
                opacity: 1,
                y: 0,
                delay: .5,
                ease: Expo.easeOut
              }, .1);
              TweenMax.to($lightMemeBox.find(".image-wrapper"), 1, {
                scaleY: 1,
                ease: Expo.easeInOut
              });
              TweenMax.to($lightMemeBox.find(".save-meme"), 1.5, {
                scaleY: 1,
                ease: Expo.easeOut
              });
              TweenMax.to($lightMemeBox.find(".save-meme span"), 1.5, {
                opacity: 1,
                y: 0
              });

              TweenMax.to($lightMemeBox.find("#meme-result .block-bg-cover"), 1.5,{
                rotationX: 0,
                delay: .8,
                ease: Back.easeOut
              });

              TweenMax.set($continueLoginModal, {autoAlpha: 0 });
            }
          });
        }
      });

      TweenMax.fromTo($(".user-container:not(.mobile)"), .5, {
        autoAlpha: 0,
      },{
        autoAlpha: 1,
        y: 0
      });

      sessionStorage.login = JSON.stringify({
        "is_logged" : true,
        "is_confirmed" : true
      });


      login_info = JSON.parse(sessionStorage.login);
    });

    $logoutBtn.on("click", function() {
      FB.logout(function(response){

        TweenMax.to($(".user-container"), out_speed, {
          autoAlpha: 0,
          ease: Expo.easeInOut
        });

        if($_body.hasClass("phone")){
          TweenMax.to($(".create-meme-link"), out_speed, {
            y: 0,
            ease: Expo.easeInOut
          });
        }

        sessionStorage.login = JSON.stringify({
          "is_logged" : false,
          "is_confirmed" : false
        });
        login_info = JSON.parse(sessionStorage.login);


        TweenMax.to($continueLoginModal, out_speed, {
          autoAlpha: 0,
          scale: .98,
          ease: Expo.easeInOut
        });
        TweenMax.to($velhotesLightboxes, in_speed, {
          autoAlpha: 0,
          ease: Expo.easeOut
        });
      });
    })

  }

  //////////////////////FACEBOOK LOGIN/////////////////////////////
  var accessToken = '208754199655464|b00f41b3d7c881fe1920a78a7af00bdf';

  // $('.share-meme').on('click', function(){

  //   url = $(this).parent('ul').find('.full-img a').attr('href');
  //   dataId = $(this).closest('.meme-container').attr('data-id');

  //   FB.ui({
  //     method: 'share',
  //     display: 'popup',
  //     href: url,
  //   }, function(response){
  //     if (response && !response.error_message) {
  //
  //       shares(url, dataId);
  //     } else {
  //
  //     }
  //   });
  // })

  function checkLoginState() {
    FB.getLoginStatus(function(response) {
      statusChangeCallback(response);

      if(response.authResponse == undefined) {
        FB.login(function(response){
          testAPI();
          // var uid = response.authResponse.userID;
        }, {scope: 'email'});
      }else {
        var uid = response.authResponse.userID;
      }

      // accessToken = response.authResponse.accessToken;
      //
    }, true);
  }

  function testAPI() {
    FB.api('/me', {fields: 'email,name,age_range,gender'}, function(response) {
      if (!response || response.error) {
        //error handle
      } else {

        $(".close-lightbox").removeClass("js-active");
        $lightMemeBox.find(".close-lightbox").addClass("js-active");
        response = JSON.parse(sessionStorage.response);
          submitJSON("saveProfile", response, function(data) {
            //Success
        }, function(data) {
            //Error
        });
        TweenMax.to($loginModal.find(".login-container .row"), .2, {

          opacity: 0,
          ease: Expo.easeOut,
          onComplete: function() {
            var left_offset = ($lightMemeBox.find(".create-meme-container").outerWidth() - $continueLoginModal.find(".login-container").outerWidth())/2,
                top_offset = ($lightMemeBox.find(".create-meme-container").outerHeight() - $continueLoginModal.find(".login-container").outerHeight())/2 + $lightMemeBox.find(".create-meme-container .save-meme").height();

            TweenMax.to($loginModal.find(".login-container"), .5, {
              width: $lightMemeBox.find(".create-meme-container").width(),
              height: $lightMemeBox.find(".create-meme-container").height() - $lightMemeBox.find(".create-meme-container .save-meme").outerHeight(),
              x: -left_offset,
              y: - $lightMemeBox.find(".create-meme-container .save-meme").outerHeight()/2,
              ease: Expo.easeOut,
              onComplete: function() {
                TweenMax.set($lightMemeBox, { autoAlpha: 1 });


                TweenMax.set($lightMemeBox.find(".save-meme"), {
                  scaleY: 0,
                  transformOrigin: "top center"
                });
                TweenMax.set($lightMemeBox.find(".save-meme span"), {
                  opacity: 0,
                  y: -20
                });

                TweenMax.set($lightMemeBox.find(".image-wrapper"), { scaleY: 0 });
                TweenMax.set($lightMemeBox.find("#meme-result .block-bg-cover"), { rotationX: -120 });

                TweenMax.set([$lightMemeBox.find(".create-meme-text-wrapper h2"), $lightMemeBox.find(".create-meme-text-wrapper h3"), $lightMemeBox.find(".create-meme-text-wrapper h2"), $lightMemeBox.find(".create-meme-text-wrapper form")], {
                  opacity: 0,
                  y: -40
                });

                TweenMax.staggerTo([$lightMemeBox.find(".create-meme-text-wrapper h2"), $lightMemeBox.find(".create-meme-text-wrapper h3"), $lightMemeBox.find(".create-meme-text-wrapper h2"), $lightMemeBox.find(".create-meme-text-wrapper form")], 2, {
                  opacity: 1,
                  y: 0,
                  delay: .5,
                  ease: Expo.easeOut
                }, .1);
                TweenMax.to($lightMemeBox.find(".image-wrapper"), 1, {
                  scaleY: 1,
                  ease: Expo.easeInOut
                });
                TweenMax.to($lightMemeBox.find(".save-meme"), 1.5, {
                  scaleY: 1,
                  ease: Expo.easeOut
                });
                TweenMax.to($lightMemeBox.find(".save-meme span"), 1.5, {
                  opacity: 1,
                  y: 0
                });

                TweenMax.to($lightMemeBox.find("#meme-result .block-bg-cover"), 1.5,{
                  rotationX: 0,
                  delay: .8,
                  ease: Back.easeOut
                });

                TweenMax.set($loginModal, {autoAlpha: 0 });
              }
            });
          }
        });

        response.url = 'http://facebook.com/'+response.id;
        response.age = response.age_range;
        response.mail = response.email;
        response.gender = response.gender;

        sessionStorage.response = JSON.stringify(response);

        sessionStorage.login = JSON.stringify({
          "is_logged" : true,
          "is_confirmed" : true
        });
        login_info = JSON.parse(sessionStorage.login);

        $(".user-container h2").text(response.name);
        $(".user-container .user-image img").attr("src", 'https://graph.facebook.com/'+response.id+'/picture?width=540&height=540')

        TweenMax.to($(".user-container:not(.mobile)"), 1, {
          autoAlpha: 1
        });

        // submitJSON("saveProfile", response, function(data) {
        //   //Success
        // }, function(data) {
        //   //Error
        // });
      }

    });

  }

  function statusChangeCallback(response) {
    // console.log(response);

    // The response object is returned with a status field that lets the
    // app know the current login status of the person.
    // Full docs on the response object can be found in the documentation
    // for FB.getLoginStatus().
    if (response.status === 'connected') {

      FB.api('/me', {fields: 'email,name,age_range,gender'}, function(response) {
        //Mostrar modal de connectec

        // console.log(response);
        response.url = 'http://facebook.com/'+response.id;
        response.age = response.age_range;
        response.mail = response.email;
        response.gender = response.gender;

        sessionStorage.response = JSON.stringify(response);

        $continueLoginModal.find(".user-name").text(response.name.split(" ")[0]);
        $continueLoginModal.find(".user-image").attr("src", 'https://graph.facebook.com/'+response.id+'/picture?width=540&height=540');

        sessionStorage.login = JSON.stringify({
          "is_logged" : true,
          "is_confirmed" : false
        });
        login_info = JSON.parse(sessionStorage.login);

        sessionStorage.user = JSON.stringify({
          'name' : response.name,
          'image' : 'https://graph.facebook.com/'+response.id+'/picture?width=540&height=540'
        });

        $(".user-container h2").text(response.name);
        $(".user-container .user-image img").attr("src", 'https://graph.facebook.com/'+response.id+'/picture?width=540&height=540')
        // console.log(response);
        // submitJSON("saveProfile", response, function(data) {
        //   //Success
        // }, function(data) {
        //   //Error
        // });

      });
    } else {
      //Mostrar modal de login

      if(!$_body.hasClass("js-no-ajax"))
        TweenMax.to($loginModal, 1, {
          autoAlpha: 1
        });

      sessionStorage.login = JSON.stringify({
        "is_logged" : false,
        "is_confirmed" : false
      });
      login_info = JSON.parse(sessionStorage.login);
    }

  }


//////////////////////////FACEBOOK LOGIN END////////////////////////////////////

function checkKeywords($this, from){
  var result = '';

  if (from === 'submit'){
    $this = $('.meme-generator-form input');
  }
  else{

    var index_sub = -1;

    memeInputOriginal = $this.val();
    memeInput = memeInputOriginal.toLowerCase();
    memeInput = removeDiacritics(memeInput);

    memeInputOriginalArray = memeInputOriginal.split(" ");


    result = memeInput.split(" ");


    $.each(keywordsWithoutAccent, function(key){
      var wordsArray = $(this)[0].keywords_expression.split("<br />");
      string = $(this)[0].keywords_expression;
      replace = $(this)[0].keywords_replace;
      var testInput = memeInput.trim();
      $.each(wordsArray, function(key){
        var testWords = wordsArray[key].trim();

        if(memeInput.indexOf(testWords) >= 0) {
          result = memeInputOriginal.replace(testWords, replace);
          $this.val(result);
          flag = true;
        }
      })
    });
  }
}

  function getMore(from){
    ////CHAMAR COM O COUTER
    if($(".meme-grid").last().attr("data-havemore") == "false");
    offset = parseInt($('.meme-grid').parent().attr('data-offset')) + 10;
    counter = parseInt($('.meme-grid').parent().attr('data-counter')) + 1;
    // TweenMax.to($('.grid-loader'), .3, {autoAlpha:1});
    $.get('/memes?offset='+offset+'&filter='+filter+'&counter='+counter, function(data){
      
      if($(data).find('.meme-grid > *').length > 0){
        $('.meme-grid').parent().append($(data).find('.meme-grid'));
        $('.meme-grid').parent().attr('data-offset', offset);
        $('.meme-grid').parent().attr('data-counter', counter);
        if (!$_body.hasClass("mobile")) {
          $(".create-new-wrapper").css("height", $(".meme-grid .meme-container").outerHeight() );
        }
        TweenMax.staggerTo($('.meme-grid:last-child .meme-container'), 0.6, {scale: 1, autoAlpha: 1, ease:Expo.easeOut, onComplete: function(){
          // TweenMax.to($('.grid-loader'), .3, {autoAlpha:0});
        }}, 0.1);

        if(_customScroll != null){
          _customScroll.update();        
        }
        getMoreFlag = false;
      }
      else{
        // TweenMax.to($('.grid-loader'), .3, {autoAlpha:0});
      }
    });
 
  }

  function filterFunction(filter){
    filterFlag = filter;
    getMoreFlag = false;
    $.get('/memes?filter='+filter, function(data){
      $('.meme-grid').nextAll('.meme-grid:first').remove();
      $('.meme-grid').parent().attr('data-offset', '0');
      $('.meme-grid').html($(data).find('.meme-grid > *'));

      if(_customScroll != null)
        _customScroll.update();

      if (!$_body.hasClass("mobile")) {
        $(".create-new-wrapper").css("height", $(".meme-grid .meme-container").outerHeight() );
      }
      TweenMax.staggerTo($('.meme-container'), 0.6, {scale: 1, autoAlpha: 1, ease:Expo.easeOut}, 0.1);

    })
  }

  $(document).on('click', '.meme-container', function(){
    shares($(this).attr('href'), $(this).attr('data-id'));
  })

  function shares(url, dataId){
    // CALL FB API AND PRINT SHARES
    $.doTimeout(1000, function(){
      $.ajax({
        url: 'https://graph.facebook.com/',
        dataType: 'jsonp',
        type: 'GET',
        data: { id: url },
        success: function(data){
          $('body').removeClass('js-loading-page');
          console.log('asdasd');
          if(data.share.share_count) {
            if(data.share.share_count >= 0){
              var response = {};
              response.id = dataId;
              response.shares = data.share.share_count;
              submitJSON("addShare", response, function(data) {
                  //Success
              }, function(data) {
                //Error

              });
            }
          }
          else{
          }
        }
      });
    })
  }


  function memePage_resize() {
    $(".corda").css("top", - (($(".create-meme-lightbox .create-meme-wrapper .create-meme-container .image-wrapper").height() - $("#meme-result").height()) / 2) - 60 + "px");
  }

  function memePage_scroll() {
	   _raf_scroll_id = _rAF_loop( memePage_scroll_rAF );
  }

  function memePage_scroll_rAF(status) {
    
    if(_customScroll == null) {
      var status = {offset: {y : -1 }, limit: { y : _scrollLimit }, direction: {y : _scrollDirection} };
      status.offset.y = window.pageYOffset;
    }
    // console.log(_scrollDirection);
    if (!$_html.hasClass("ie")) {
      // if(!$_body.hasClass("phone")) {
      //   TweenMax.to($headerMain.find(".header-global"), 1, {y: 6, force3D:true, ease: Expo.easeOut, className:"+=active"});
      // } else {
      //   TweenMax.to($headerMain.find(".header-global"), 1, {force3D:true, ease: Expo.easeOut, className:"+=active"});
      // }
      $headerGlobal.css({"top" : status.offset.y +"px"});
      if(_scrollY > 0 && !$headerGlobal.hasClass("active")) {
        TweenMax.set($headerMain.find(".header-global"), {
          className:"+=active"
        });
        $headerGlobal.addClass("active");
      } else if(_scrollY == 1 && $headerGlobal.hasClass("active")) {
        $headerGlobal.removeClass("active");
      }
    }
    if(verge.inViewport($pageFooter) && _scrollDirection == "down" && !getMoreFlag) {
      getMoreFlag = true;
      getMore();
      
      console.log('FUCNTION CALL')
    }
  }

} //////end function homePage