function homePage(do_home) {
  if (!do_home) {
    $_window.off("scroll.homePage");
    $_window.off("mousewheel.bottlesSlider");
    $_window.off("resize.homePage");
    $.doTimeout("sliderScroling");
    return false;
  } else {
    if (_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      _scrollRef = function () {
        _raf_loop_id = _rAF_loop(homePage_scroll_rAF);
      };
      $_window[0].addEventListener("scroll", _scrollRef, { passive: true });
    } else {
      _page_scroll_listener = function (status) {
        homePage_scroll_rAF(status);
      };

      _customScroll.addListener(_page_scroll_listener);
    }
  }

  /*variables*/
  var borderAnim = new TimelineMax(),
    entrance = new TimelineMax(),
    anim = false,
    bottle_list = bottleList(),
    scrollTimeout = null,
    numOfResize = 0,
    randomNum = Math.floor(Math.random() * 3 + 0),
    mainTitle = [
      "Gosto<br>deste Porto.",
      "Era moda no porto antes do porto ser&nbsp;moda.",
      "Brindemos<br>a isso.",
    ];

  /*DOM variables*/
  var $mask = $(".ink-mask"),
    $frame = $(".frame"),
    $phrase = $("#legal-container").find("h2"),
    $yesBtn = $("#yes"),
    $trigger = $("#trigger"),
    $suggestionInfo = $(".suggestion-info"),
    $bottleWrapper = $("#bottles-container"),
    $bottleListContainer = $(".bottles-list-container"),
    $bottlesList = $(".bottles-list"),
    $background = $(".bottles-wrapper"),
    $mark = $("#watermark"),
    $suggestionBtn = $(".suggestion-btn"),
    $technicalBtn = $(".technical-btn"),
    $bottleSlider = $(".bottle-slider"),
    $mobileBottleInfo = $(".mobile-bottle-info"),
    $closeBtn = $("#close-btn"),
    $otherInfoContainer = $(".other-info-container"),
    $slogan = $("#slogan"),
    $buttonsInfoContainerItem = $(".buttons-info-container ul li"),
    $mobileBottleInfoItem = $(".mobile-bottle-info .info-buttons li"),
    $tabletBottleInfoItem = $(".tablet-bottle-info .info-buttons li"),
    $ulNotTechnicalInfoItem = $("ul:not(.technical-info) li"),
    $quote = $(".quote h2"),
    $suggestionInfoLastItem = $(".suggestion-info:last-of-type"),
    $scrollHelper = $(".scroll-helper h2"),
    $feedbackContainer = $(".feedback-container"),
    $thirdElem = $(".bottle-slider").eq(2).find(".bottle-photo img"),
    $firstElem = $(".bottle-slider").eq(0).find(".bottle-photo img");
  ($lastElem = $(".bottle-slider").eq(5).find(".bottle-photo img")),
    ($quoteParent = $(".quote")),
    ($scrollHelperParent = $(".scroll-helper")),
    ($memeLinkVelhotes = $("#memes-velhotes")),
    ($memeLinkCria = $("#cria-o-meme")),
    ($smileContainer = $("#smile-container")),
    ($createMemeBtn = $("#create-meme-btn")),
    ($createMemeBtnA = $("#create-meme-btn").parent("a")),
    ($velhotesLogo = $(".velhotes-logo")),
    ($menuBurguerOpen = $(".offmenu-btn .text.open")),
    ($menuBurguerClose = $(".offmenu-btn .text.close")),
    ($headerMain = $("#header-main")),
    ($offMenu = $(".offmenu")),
    ($mainNavigation = $offMenu.find(".main-navigation")),
    ($openMenu = $(".open-menu"));

  /*inits*/
  // init();
  // TweenMax.set($("#create-meme-btn").parent().parent(), {autoAlpha:0});
  breakTitleLetters($memeLinkVelhotes);
  breakTitleLetters($memeLinkCria);
  breakTitleLetters($menuBurguerOpen);
  breakTitleLetters($menuBurguerClose);
  $slogan.html(mainTitle[randomNum]);
  breakTitleLetters($scrollHelper);
  if (!$_body.hasClass("mobile")) {
    bottle_list.init();
  }
  if (!$_html.hasClass("ie")) {
    $(".bottles-wrapper").addClass("mobile-align");
  }
  if ($_body.hasClass("mobile")) {
    $(".legal-btn, footer a").addClass("hover-disabled");
  }

  $createMemeBtnA.removeClass("create-meme-modal");

  imgNumber = Math.floor(Math.random() * 3 + 1);
  $(".off-box img").attr("src", "/public/imgs/home/img" + imgNumber + ".jpg");

  // $('.legal-btn').on('click', function(){

  //   // FB.getLoginStatus(function(response) {
  //   //         if (response.status === 'connected') {
  //   //           console.log(response);
  //   //           console.log('Logged in.');
  //   //         }
  //   //         else {
  //   //           FB.login();
  //   //           // console.log(response.status);
  //   //         }
  //   //       });

  //   FB.login(function(response) {
  //     if (response.status === 'connected') {
  //       console.log(response.status);
  //       FB.logout(function(response) {
  //          // FB.login();
  //          console.log(response.status);
  //       });
  //     } else {
  //       FB.login(function(){
  //         console.log('not connected '+response.status);
  //       });
  //       // The person is not logged into this app or we are unable to tell.
  //     }
  //   });
  // })

  /*events*/
  $createMemeBtnA.off("click");
  TweenMax.to($(".user-container"), 0.2, {
    autoAlpha: 0,
  });

  $createMemeBtn.click(function () {
    cookie.set("click", "true");
  });

  $velhotesLogo.click(function (event) {
    event.stopImmediatePropagation();
    event.preventDefault();
    event.stopPropagation();
  });

  // $('.off-box .close-box').on('click', function(){
  //   TweenMax.to($('.off-box'),.5,{x: '-100%', ease: Expo.easeOut});
  // })

  if (!$("body").hasClass("js-no-ajax")) {
    TweenMax.to($(".donations-modal"), 0.5, { x: 0 });
  }

  if ($_body.hasClass("mobile")) {
    $(".donations-modal .close").on("click", function () {
      TweenMax.to($(".donations-modal"), 0.5, { x: "-100%" });
    });
  }

  $yesBtn.on("click", function () {
    var $fakeBottle = $(".fake-bottle img");
    // TweenMax.set($("#create-meme-btn").parent().parent(), {autoAlpha:1});
    TweenMax.set("#create-meme-btn", { autoAlpha: 1 });
    TweenMax.to(".letters", 0.4, { autoAlpha: 0, ease: Expo.easeOut });

    TweenMax.to($(".donations-modal"), 0.5, { x: 0, delay: 2.5 });
    $("body").attr("data-init", "true");
    if ($_html.hasClass("ie")) {
      TweenMax.to($velhotesLogo, 0.8, {
        y: 0,
        force3D: true,
        ease: Expo.easeOut,
      });
      TweenMax.to($headerMain.find(".header-global"), 0.8, {
        y: 0,
        force3D: true,
        ease: Expo.easeOut,
      });
      TweenMax.to(".velhotes-logo svg", 0.8, {
        scale: 1.4,
        force3D: true,
        ease: Expo.easeOut,
      });
    } else {
      // if(!$_body.hasClass("phone")) {
      TweenMax.to($velhotesLogo, 0.8, {
        y: 0,
        force3D: true,
        ease: Expo.easeOut,
      });
      TweenMax.to($headerMain.find(".header-global"), 0.8, {
        y: 0,
        force3D: true,
        ease: Expo.easeOut,
      });
      // }

      TweenMax.to(".velhotes-logo svg", 0.8, {
        scale: 1,
        force3D: true,
        ease: Expo.easeOut,
      });
    }

    if ($_html.hasClass("ie") || $_html.hasClass("edge")) {
      TweenMax.to($mask, 1, { opacity: 0, ease: Expo.easeOut });
      $frame.addClass("visible");
      borderAnim
        .to($(".border.left"), 0.3, { height: "100vh" })
        .to($(".border.top"), 0.3, { width: "100vw" })
        .to($(".border.right"), 0.3, { height: "100vh" })
        .to($(".border.bottom"), 0.3, {
          width: "100vw",
          onComplete: function () {
            $mask.hide();
            TweenMax.to($frame, 1, {
              opacity: 0,
              ease: Linear.easeNone,
              delay: 1,
            });
            TweenMax.to(
              $(".bottle-slider").eq(0).find(".bottle-photo img"),
              2,
              {
                opacity: 1,
                y: 0,
                scale: 1,
                ease: Expo.easeOut,
                delay: 1.8,
                onComplete: function () {
                  TweenMax.set(".scroll-helper", { autoAlpha: 1 });
                  TweenMax.staggerTo(
                    $scrollHelperParent.find(".char"),
                    2,
                    { opacity: 1, ease: Expo.easeOut, yoyo: true, repeat: -1 },
                    0.1
                  );
                  TweenMax.to(".arrow-container", 2, {
                    opacity: 1,
                    ease: Expo.easeOut,
                  });
                },
              }
            );

            $.doTimeout(2000, function () {
              $slogan.addClass("change-spacing");
            });
            $.doTimeout(2500, function () {
              TweenMax.to($(".off-box"), 0.5, { x: 0 });
            });
            $.doTimeout(2500, function () {
              TweenMax.to($(".donations-modal"), 0.5, { x: 0 });
            });
            $.doTimeout(2400, function () {
              TweenMax.set($memeLinkVelhotes, { autoAlpha: 1 });
              TweenMax.staggerTo(
                $memeLinkVelhotes.find(".char"),
                1.4,
                { y: 0, autoAlpha: 1, ease: Expo.easeOut },
                0.1
              );

              TweenMax.set($openMenu, { autoAlpha: 1 });
              TweenMax.staggerTo(
                $openMenu.find(".text.open .char"),
                1.4,
                { y: 0, autoAlpha: 1, ease: Expo.easeOut },
                0.1
              );
              TweenMax.staggerTo(
                $openMenu.find(".burguer"),
                1.4,
                {
                  x: "0%",
                  autoAlpha: 1,
                  ease: Expo.easeOut,
                  onComplete: function () {
                    $openMenu.find(".burguer-wrapper").removeClass("hidden");
                    TweenMax.set($openMenu.find(".burguer"), {
                      clearProps: "all",
                    });
                    $openMenu.css("pointer-events", "visible");
                  },
                },
                0.1
              );
            });
            $.doTimeout(3000, function () {
              _customScroll.registerEvents(/scroll/, /wheel/, /touchstart/);
            });
            $.doTimeout(3200, function () {
              $smileContainer.addClass("openSmile");
              $createMemeBtn.addClass("openFace");
            });
            $.doTimeout(3500, function () {
              TweenMax.to(
                "#smile-container #wink-container span:first-of-type",
                0.6,
                { rotation: 35, force3D: true, ease: Expo.easeOut }
              );
              TweenMax.to(
                "#smile-container #wink-container span:last-of-type",
                0.6,
                { rotation: -35, force3D: true, ease: Expo.easeOut }
              );
            });
          },
        });
    } else {
      if ($_body.hasClass("mobile")) {
        $mask.addClass("in");
        $frame.addClass("visible");
        borderAnim
          .to($(".border.left"), 0.3, { height: "100vh" })
          .to($(".border.top"), 0.3, { width: "100vw" })
          .to($(".border.right"), 0.3, { height: "100vh" })
          .to($(".border.bottom"), 0.3, {
            width: "100vw",
            onComplete: function () {
              $mask.hide();
              TweenMax.to($frame, 1, {
                opacity: 0,
                ease: Linear.easeNone,
                delay: 1,
              });
              TweenMax.to("#bottle-mobile", 3, {
                y: 0,
                x: 0,
                rotation: 0,
                delay: 2,
                ease: Expo.easeOut,
              });
              $.doTimeout(2500, function () {
                $slogan.addClass("change-spacing");
              });
              $.doTimeout(3000, function () {
                _customScroll.registerEvents(/scroll/, /wheel/, /touchstart/);
              });
            },
          });
      } else {
        $mask.addClass("in");
        $frame.addClass("visible");
        borderAnim
          .to($(".border.left"), 0.3, { height: "100vh" })
          .to($(".border.top"), 0.3, { width: "100vw" })
          .to($(".border.right"), 0.3, { height: "100vh" })
          .to($(".border.bottom"), 0.3, {
            width: "100vw",
            onComplete: function () {
              $mask.hide();
              $.doTimeout(1200, function () {
                $slogan.addClass("change-spacing");
              });
              $.doTimeout(1800, function () {
                TweenMax.to($(".off-box"), 0.5, { x: 0, ease: Expo.easeOut });
                TweenMax.to($(".donations-modal"), 0.5, {
                  x: 0,
                  ease: Expo.easeOut,
                });
              });
              $.doTimeout(3000, function () {
                _customScroll.registerEvents(/scroll/, /wheel/, /touchstart/);
              });
            },
          });
      }

      $.doTimeout(1000, function () {
        bottle_list.enter();
      });
      $.doTimeout(2400, function () {
        if ($_body.hasClass("phone")) {
          TweenMax.to($(".create-meme-link"), 0.5, { autoAlpha: 1 });
        }

        TweenMax.set($memeLinkVelhotes, { autoAlpha: 1 });
        TweenMax.staggerTo(
          $memeLinkVelhotes.find(".char"),
          1.4,
          { y: 0, autoAlpha: 1, ease: Expo.easeOut },
          0.1
        );

        TweenMax.set($openMenu, { autoAlpha: 1 });
        TweenMax.staggerTo(
          $openMenu.find(".text.open .char"),
          1.4,
          { y: 0, autoAlpha: 1, ease: Expo.easeOut },
          0.1
        );
        TweenMax.staggerTo(
          $openMenu.find(".burguer"),
          1.4,
          {
            x: "0%",
            autoAlpha: 1,
            ease: Expo.easeOut,
            onComplete: function () {
              $openMenu.find(".burguer-wrapper").removeClass("hidden");
              TweenMax.set($openMenu.find(".burguer"), { clearProps: "all" });
              $openMenu.css("pointer-events", "visible");
            },
          },
          0.1
        );
      });
      $.doTimeout(3200, function () {
        $smileContainer.addClass("openSmile");
        $createMemeBtn.addClass("openFace");
      });
      $.doTimeout(3500, function () {
        TweenMax.to(
          "#smile-container #wink-container span:first-of-type",
          0.6,
          { rotation: 35, force3D: true, ease: Expo.easeOut }
        );
        TweenMax.to("#smile-container #wink-container span:last-of-type", 0.6, {
          rotation: -35,
          force3D: true,
          ease: Expo.easeOut,
        });
      });
    }
  });

  if (!$_body.hasClass("mobile")) {
    $background.on("mousemove", function (e) {
      var offsetX = 0.5 - e.pageX / _globalViewportW,
        offsetY = 0.5 - e.pageY / _globalViewportH,
        offset = 35;

      TweenMax.to($mark.find("span"), 1, {
        y: Math.round(offsetY * offset),
        x: Math.round(offsetX * offset),
        force3D: true,
      });
    });
  }

  $ulNotTechnicalInfoItem.click(function () {
    var $this = $(this);
    $this
      .closest("ul:not(.technical-info)")
      .find("li.active")
      .removeClass("active");
    $this.addClass("active");
  });

  $otherInfoContainer.each(function () {
    var $this = $(this),
      $containerHeight = $this.height();
    $this.css("height", $containerHeight);
  });

  $suggestionInfoLastItem.html(function () {
    var text = $(this).text().trim().split(" "),
      last = text.pop();
    return (
      text.join(" ") +
      (text.length > 0
        ? " <span class='quotation-word'>" + last + "</span>"
        : last)
    );
  });

  $quote.html(function () {
    var text = $(this).text().trim().split(" "),
      first = text.shift(),
      last = text.pop();
    return (
      (text.length > 0
        ? "<span class='first-word'>" + first + "</span> "
        : first) +
      text.join(" ") +
      (text.length > 0
        ? " <span class='quotation-word'>" + last + "</span>"
        : last)
    );
  });

  var feedBackHeight = $feedbackContainer.find("h3").height();
  $feedbackContainer.css("height", feedBackHeight);

  // var x = $(".ink-mask .bg-layer.show h3").offset(),
  //     marT = $(".ink-mask .bg-layer.show h3").css("marginTop"),
  //     h = $velhotesLogo.height(),
  //     r = parseInt(marT);

  // if (!$_body.hasClass("phone")) {
  //   TweenMax.set($velhotesLogo, {y: x.top - h * 5 - r * 1.3, force3D: true});
  // } else {
  //   TweenMax.set($velhotesLogo, {y: x.top - h * 5 - r / 2, force3D: true});
  // }

  $ulNotTechnicalInfoItem.hover(
    function () {
      if ($(this).hasClass("active")) {
        return;
      } else {
        if ($(this).hasClass("unmask")) {
          $(this).css("opacity", "1");
          $(this)
            .closest(".bottle-slider ul:not(.technical-info)")
            .find("li.active")
            .css("opacity", "0.3");
        }
      }
    },
    function () {
      $(this)
        .closest(".bottle-slider ul:not(.technical-info)")
        .find("li.active")
        .css("opacity", "1");
      $(this)
        .closest(".bottle-slider ul:not(.technical-info)")
        .find("li:not(.active)")
        .css("opacity", "0.3");
    }
  );

  $createMemeBtn.hover(
    function () {
      TweenMax.to("#smile-container #wink-container span:first-of-type", 0, {
        rotation: 0,
        force3D: true,
      });
      TweenMax.to("#smile-container #wink-container span:last-of-type", 0, {
        rotation: 0,
        force3D: true,
      });
    },
    function () {
      TweenMax.to("#smile-container #wink-container span:first-of-type", 0, {
        rotation: 35,
        force3D: true,
      });
      TweenMax.to("#smile-container #wink-container span:last-of-type", 0, {
        rotation: -35,
        force3D: true,
      });
    }
  );

  $buttonsInfoContainerItem.click(function () {
    var type = $(this).attr("data-button"),
      $bottleSlider = $(".bottle-slider");
    TweenMax.to($(this).closest($bottleSlider).find(".info"), 0.5, {
      y: 20,
      opacity: 0,
      ease: Expo.easeOut,
    });
    TweenMax.to(
      $(this)
        .closest($bottleSlider)
        .find($("." + type + "-info")),
      1,
      { y: 0, opacity: 1, ease: Expo.easeOut }
    );
  });

  $mobileBottleInfoItem.click(function () {
    var type = $(this).attr("data-button"),
      $mobileBottleInfo = $(".mobile-bottle-info");
    TweenMax.to($(this).closest($mobileBottleInfo).find(".pos-center"), 0.5, {
      y: 20,
      opacity: 0,
      ease: Expo.easeOut,
    });
    TweenMax.to(
      $(this)
        .closest($mobileBottleInfo)
        .find($("." + type + "-mobile-container")),
      1,
      { y: 0, opacity: 1, ease: Expo.easeOut }
    );
  });

  $tabletBottleInfoItem.click(function () {
    var type = $(this).attr("data-button"),
      $tabletBottleInfo = $(".tablet-bottle-info");
    TweenMax.to($(this).closest($tabletBottleInfo).find(".pos-center"), 0.5, {
      y: 20,
      opacity: 0,
      ease: Expo.easeOut,
    });
    TweenMax.to(
      $(this)
        .closest($tabletBottleInfo)
        .find($("." + type + "-tablet-container")),
      1,
      { y: 0, opacity: 1, ease: Expo.easeOut }
    );
  });

  $closeBtn.click(function () {
    TweenMax.to($mobileBottleInfo, 0, {
      scale: 0.95,
      autoAlpha: 0,
      ease: Expo.easeOut,
    });
    $(".mobile-bottle-info ul li").removeClass("active");
    $(".mobile-bottle-info ul li[data-button=description]").addClass("active");
    TweenMax.to(".description-mobile-container", 0, {
      y: 0,
      opacity: 1,
      ease: Expo.easeOut,
    });
    TweenMax.to(
      ".technical-mobile-container, .suggestion-mobile-container",
      0,
      { y: 20, opacity: 0, ease: Expo.easeOut }
    );
  });

  if ($_body.hasClass("phone")) {
    $bottleSlider.click(function () {
      var title = $(this).find(".bottle-title"),
        description = $(this).find(".bottle-info"),
        suggestion = $(this).find(".suggestion-info"),
        technical = $(this).find(".technical-info").html(),
        color = $(this).attr("data-color");

      if ($(this).find(".buy-now-btn").hasClass("disabled"))
        $mobileBottleInfo.find(".buy-now-btn").addClass("disabled");
      else $mobileBottleInfo.find(".buy-now-btn").removeClass("disabled");

      if ($(this).find(".buy-now-btn").hasClass("hide-btn"))
        $mobileBottleInfo.find(".buy-now-btn").addClass("hide-btn");
      else $mobileBottleInfo.find(".buy-now-btn").removeClass("hide-btn");

      $mobileBottleInfo
        .find(".buy-now-btn span")
        .text($(this).find(".buy-now-btn span").eq(0).text());
      $mobileBottleInfo
        .find(".buy-now-btn")
        .attr("href", $(this).find(".buy-now-btn").attr("href"));
      $mobileBottleInfo.find("h2").text(title[0].innerText);
      $mobileBottleInfo
        .find(".description-mobile-container p:first-of-type")
        .html(description[0].innerText);
      $mobileBottleInfo
        .find(".description-mobile-container p:last-of-type")
        .html(description[1].innerText);
      $mobileBottleInfo
        .find(".suggestion-mobile-container p:first-of-type")
        .html(suggestion[0].innerText);
      $mobileBottleInfo
        .find(".suggestion-mobile-container p:last-of-type")
        .html(suggestion[1].innerText);
      $mobileBottleInfo.find(".technical-mobile-container ul").html(technical);
      $mobileBottleInfo
        .find("h2, p, li, #close-btn, .buy-now-btn")
        .attr("data-color", color);
      TweenMax.to($mobileBottleInfo, 1, {
        scale: 1,
        autoAlpha: 1,
        ease: Expo.easeOut,
      });
    });
  }

  if (_globalViewportW <= 768 && _globalViewportW > 414) {
    $bottleSlider.each(function () {
      var $this = $(this),
        description = $this.find(".bottle-info"),
        suggestion = $this.find(".suggestion-info"),
        technical = $this.find(".technical-info").html(),
        color = $this.attr("data-color");

      $this
        .find($(".tablet-bottle-info").data("id", color))
        .find(".description-tablet-container p:first-of-type")
        .html(description[0].innerText);
      $this
        .find($(".tablet-bottle-info").data("id", color))
        .find(".description-tablet-container p:last-of-type")
        .html(description[1].innerText);
      $this
        .find($(".tablet-bottle-info").data("id", color))
        .find(".suggestion-tablet-container p:first-of-type")
        .html(suggestion[0].innerText);
      $this
        .find($(".tablet-bottle-info").data("id", color))
        .find(".suggestion-tablet-container p:last-of-type")
        .html(suggestion[1].innerText);
      $this
        .find($(".tablet-bottle-info").data("id", color))
        .find(".technical-tablet-container ul")
        .html(technical);
    });
  }

  $(".bottle-title").each(function () {
    var $this = $(this),
      text = $this.html(),
      ph = text.split("<br>");

    $this.html(" ");

    for (var i = 0; i < ph.length; i++) {
      var words = "<div class='words-wrapper'>" + ph[i] + "</div>";
      $this.append(words);
    }
  });

  $(".words-wrapper").each(function () {
    var $this = $(this),
      text = $this.html(),
      s = '<span class="underline">';

    $this.prop("innerHTML", function (_, html) {
      s += html
        .replace(/&amp;/g, "&")
        .replace(
          /(g|j|p|q|y|Q|@|{|_|\(|\)|\[|\||\]|}|;|,|§|µ|ç|\/)/g,
          '</span>$1<span class="underline">'
        );
      s += "</span>";
      $(this).html(s);
    });
  });

  if ($_body.hasClass("phone")) {
    breakBySpace();
  }

  $(".underline").each(function () {
    var $this = $(this),
      color = $(this).closest(".bottle-title").attr("data-color");
    $this.attr("data-color", color);
  });

  $_window.on("resize.homePage", $.debounce(500, homePage_resize));

  /*functions*/
  function breakBySpace() {
    var strSpecial = $(".bottles-wrapper .bottle-title[data-color=special]")
        .find(".words-wrapper:last-of-type .underline")
        .text(),
      strLagrima = $(".bottles-wrapper .bottle-title[data-color=lagrima]")
        .find(".words-wrapper .underline:last-of-type")
        .text(),
      strWhite = $(
        ".bottles-wrapper .bottle-title[data-color=whiteWine]"
      ).text(),
      wordsSpecial = strSpecial.split(" "),
      wordsWhite = strWhite.split(" "),
      wordsLagrima = strLagrima.split(" ");

    $(".bottle-title[data-color=special]")
      .find(".words-wrapper:last-of-type .underline")
      .html(" ");
    $(".bottle-title[data-color=lagrima]")
      .find(".words-wrapper .underline:last-of-type")
      .html(" ");

    for (var i = 0; i < wordsSpecial.length - 1; i++) {
      wordsSpecial[i] += " ";
      $(".bottle-title[data-color=special]")
        .find(".words-wrapper:last-of-type")
        .html(
          '<span class="underline">' +
            wordsSpecial[0] +
            '</span><span class="underline">' +
            wordsSpecial[1] +
            "</span>"
        );
    }
    for (var i = 0; i < wordsLagrima.length - 1; i++) {
      wordsLagrima[i] += " ";
      $(".bottle-title[data-color=lagrima]")
        .find(".words-wrapper .underline:last-of-type")
        .html(
          '<span class="underline">' +
            wordsLagrima[0] +
            '</span><span class="underline">' +
            wordsLagrima[1] +
            "</span>"
        );
    }
    for (var i = 0; i < wordsWhite.length; i++) {
      wordsWhite[i] += " ";
      $(".bottle-title[data-color=whiteWine]").html(
        '<span class="underline">' +
          wordsWhite[0] +
          '</span><span class="underline">' +
          wordsWhite[1] +
          '</span><span class="underline">' +
          wordsWhite[2] +
          "</span>"
      );
    }

    $(".underline").each(function () {
      var $this = $(this),
        color = $(this).closest(".bottle-title").attr("data-color");
      $this.attr("data-color", color);
    });
  }

  // function init(){
  //   if ($_html.hasClass("ie")) {
  //     TweenMax.to(".velhotes-logo svg", 2, {scale: 2, autoAlpha: 1, ease: Expo.easeOut});
  //   } else {
  //     TweenMax.to(".velhotes-logo svg", 2, {scale: 1.4, autoAlpha: 1, ease: Expo.easeOut});
  //   }
  // 	$(".bg-layer").addClass("show");
  // 	$.doTimeout(800, function(){
  // 	  $(".legal-btn").removeClass("show");
  // 	})
  // }

  function bottleList() {
    var $bottleWrapper = $("#bottles-container"),
      $bottleWrapperInner = $(".bottles-wrapper"),
      $bottleSlide = $(".bottle-slider"),
      $fakeBottle = $(".fake-bottle img"),
      $bottlesList = $(".bottles-list li"),
      $watermark = $("#watermark"),
      $bottleParallax = $(".bottle-parallax"),
      $bottleParallaxSecond = $(".bottle-parallax-second"),
      bottleWrapperObj = {},
      bottlesScroll = null,
      inBottleList = false,
      first_down = true,
      is_inText = false,
      centerTween = null,
      active_index = 0;

    var init = function () {
      bottleWrapperObj.element = $bottleWrapper;
      bottleWrapperObj.offsetY = $bottleWrapper.offset().top;
      bottleWrapperObj.height = $bottleWrapper.height();
      bottleWrapperObj.bottles = [];
      bottleWrapperObj.bottlesList = [];

      $bottleParallax.each(function () {
        var $this = $(this);

        TweenMax.set($this, {
          "-webkit-clip-path": "inset(" + $this.outerHeight() + "px 0px 0px)",
          "clip-path": "inset(" + $this.outerHeight() + "px 0px 0px)",
        });
      });
      $bottleParallaxSecond.each(function () {
        var $this = $(this);

        TweenMax.set($this, { y: 20, opacity: 0 });
      });

      $bottlesList.each(function (index) {
        var $this = $(this);
        bottleWrapperObj.bottlesList.push($this);
      });

      $bottleSlide.each(function (index) {
        var $this = $(this),
          elem = {};

        elem.element = $this;
        elem.photo = $this.find(".bottle-photo");
        elem.parallax = $this.find(".bottle-parallax");
        elem.parallaxSecond = $this.find(".bottle-parallax-second");
        elem.offsetY = $this.offset().top;
        elem.bottomY = $this.offset().top + $this.height();

        bottleWrapperObj.bottles.push(elem);

        if (index == 0) {
          TweenMax.set($this.find(".bottle-photo"), {
            y: -_globalViewportH + "px",
            autoAlpha: 1,
          });
        }
      });

      $bottlesList.on("click", function () {
        var $this = $(this),
          scroll_val = { a: _customScroll.offset.y };

        if ($this.hasClass("js-active")) return;

        var target_index = $bottlesList.index($this);

        centerTween = TweenMax.to(scroll_val, 1, {
          a: bottleWrapperObj.bottles[target_index].offsetY,
          ease: Power4.easeOut,
          onUpdate: function () {
            _customScroll.setPosition(0, scroll_val.a);
          },
        });
      });

      $bottlesList.hover(
        function () {
          var $this = $(this);

          if ($this.hasClass("js-active")) return;

          if (
            _scrollY > _globalViewportH &&
            _scrollY + _globalViewportH <
              bottleWrapperObj.offsetY + bottleWrapperObj.height
          ) {
            //TweenMax.killTweensOf($this);
            TweenMax.to($this.find("img"), 1, {
              x: "-20px",
              ease: Power2.easeOut,
            });
          }
        },
        function () {
          var $this = $(this);

          if (
            _scrollY > _globalViewportH &&
            _scrollY + _globalViewportH <
              bottleWrapperObj.offsetY + bottleWrapperObj.height
          ) {
            //TweenMax.killTweensOf($this);
            TweenMax.to($this.find("img"), 1, {
              x: "0px",
              ease: Power2.easeOut,
            });
          }
        }
      );

      $_window.on("mousewheel.bottlesSlider", function (e) {
        if (centerTween) centerTween.kill();
      });
    };

    var anim = new TimelineMax();

    var enter = function () {
      TweenMax.set($bottleSlide.eq(0).find(".bottle-photo img"), { y: 60 });
      if (!$_body.hasClass("mobile")) {
        $.doTimeout(500, function () {
          anim
            .to(".frame", 0.7, {
              scale: 0.4,
              y: "2%",
              ease: Expo.easeOut,
              force3D: true,
            })
            .to(".frame", 0.1, { autoAlpha: 0, ease: Expo.easeOut }, "-=0.8")
            .to(
              "#bottle-mobile",
              0.7,
              {
                scale: 0.9,
                y: 60,
                force3D: true,
                ease: Expo.easeOut,
                onComplete: function () {
                  TweenMax.set("#bottle-mobile", { autoAlpha: 0 });
                  TweenMax.set($bottleSlide.eq(0).find(".bottle-photo img"), {
                    opacity: 1,
                  });
                  TweenMax.set(".scroll-helper", { autoAlpha: 1 });
                  TweenMax.staggerTo(
                    $scrollHelperParent.find(".char"),
                    2,
                    { opacity: 1, ease: Expo.easeOut, yoyo: true, repeat: -1 },
                    0.1
                  );
                  TweenMax.to(".arrow-container", 2, {
                    opacity: 1,
                    ease: Expo.easeOut,
                  });
                },
              },
              "-=0.7"
            );
        });
      } else if ($_body.hasClass("mobile")) {
        TweenMax.to($bottleSlide.eq(0).find(".bottle-photo img"), 2, {
          opacity: 1,
          y: "0px",
          scale: 1,
          ease: Expo.easeOut,
          delay: 1.8,
          onComplete: function () {
            TweenMax.set(".scroll-helper", { autoAlpha: 1 });
            TweenMax.staggerTo(
              $scrollHelperParent.find(".char"),
              2,
              { opacity: 1, ease: Expo.easeOut, yoyo: true, repeat: -1 },
              0.1
            );
            TweenMax.to(".arrow-container", 2, {
              opacity: 1,
              ease: Expo.easeOut,
            });
          },
        });
      } else if ($_body.hasClass("phone")) {
        TweenMax.to(".scroll-helper", 1, {
          autoAlpha: 1,
          delay: 3,
          ease: Expo.easeOut,
        });
        TweenMax.staggerTo(
          $scrollHelperParent.find(".char"),
          2,
          { opacity: 1, ease: Expo.easeOut, yoyo: true, repeat: -1 },
          0.1
        );
        TweenMax.to(".arrow-container", 2, { opacity: 1, ease: Expo.easeOut });
      }
    };

    var fixFirst = function (status) {
      var offset = -_globalViewportH + status.offset.y;

      if (!$_body.hasClass("mobile")) {
        TweenMax.set($bottleSlide.eq(0).find(".bottle-photo"), {
          y: offset + "px",
        });
      }
    };

    var update = function (status) {
      if (
        status.offset.y + _globalViewportH - 50 > bottleWrapperObj.offsetY &&
        status.offset.y <
          bottleWrapperObj.offsetY +
            bottleWrapperObj.height -
            _globalViewportH / 2
      ) {
        if (
          status.direction.y == "up" &&
          status.offset.y < bottleWrapperObj.offsetY
        ) {
          bottle_list.closeBottles();
        } else {
          if (inBottleList) return;

          if (status.offset.y > _globalViewportH)
            bottle_list.openBottles("bottom");
          else {
            bottle_list.openBottles("top");
          }
        }
      } else {
        bottle_list.closeBottles();
      }
    };

    var center = function () {
      var $active = $bottleSlide.filter(".js-active"),
        active_index = $bottleSlide.index($active),
        scroll_val = { a: _customScroll.offset.y };

      if (active_index < 0 || active_index == undefined) return;

      if (!$_body.hasClass("mobile")) {
        if (active_index == 0 && _customScroll.offset.y < _globalViewportH / 2)
          return;

        centerTween = TweenMax.to(scroll_val, 1, {
          a: bottleWrapperObj.bottles[active_index].offsetY,
          ease: Power4.easeOut,
          onUpdate: function () {
            _customScroll.setPosition(0, scroll_val.a);
          },
          onComplete: function () {
            centerTween = null;
          },
        });
      }
    };

    var openBottles = function (from) {
      if ($bottleWrapper.hasClass("js-bottle-handler-open")) return;

      inBottleList = true;

      if (from == "top") {
        if (first_down) {
          first_down = false;

          //start animation loop
          if (!$_body.hasClass("mobile") || _globalViewportW > 1024) {
            animationLoop();
          }
        }
      }

      TweenMax.to($bottleWrapper.find(".bottles-list"), 1, {
        // x: "0px",
        x: "40px",
        delay: 0.5,
      });

      $bottleWrapper.addClass("js-bottle-handler-open");

      TweenMax.to($scrollHelperParent.find(".arrow-container"), 1, {
        opacity: 0,
        ease: Expo.easeOut,
      });
      TweenMax.staggerTo(
        $scrollHelperParent.find(".char"),
        2,
        { opacity: 0, ease: Expo.easeOut },
        0.1
      );
      TweenMax.killTweensOf($bottleSlide.eq(0).find(".bottle-photo img"));
      TweenMax.to($bottleSlide.eq(0).find(".bottle-photo img"), 1, {
        y: "0px",
      });
    };

    var closeBottles = function () {
      if (!$bottleWrapper.hasClass("js-bottle-handler-open")) return;

      inBottleList = false;

      $bottleWrapper.removeClass("js-bottle-handler-open");

      TweenMax.to($bottleWrapper.find(".bottles-list"), 1, {
        x: "150px",
      });

      TweenMax.to($watermark, 0.2, {
        autoAlpha: 0,
      });

      TweenMax.killTweensOf($bottleSlide.eq(0).find(".bottle-photo img"));
      TweenMax.to($bottleSlide.eq(0).find(".bottle-photo img"), 1, {
        y: "60px",
      });
    };

    function animationLoop() {
      _rAF_loop(animationLoop);

      if (_blockAll) return;

      //Check if scroll is somewhere inside 1/2 of the screen in the center
      if (
        _scrollY + _globalViewportH / 2 > bottleWrapperObj.bottles[0].offsetY &&
        !bottleWrapperObj.bottles[0].parallax.hasClass("js-animated")
      ) {
        bottleWrapperObj.bottles[0].parallax.addClass("js-animated");

        for (
          var a = 0, len = bottleWrapperObj.bottles[0].parallax.length;
          a < len;
          a++
        ) {
          bottleWrapperObj.bottles[0].parallax.eq(a).addClass("delay-" + a);

          if (a == 0) {
            var factor = 0.3;
            var factor_delay = 0;
          } else {
            var factor = 0.1;
            var factor_delay = 0.25;
          }

          if (
            bottleWrapperObj.bottles[0].parallax.eq(a).hasClass("technical-btn")
          ) {
            TweenMax.to(bottleWrapperObj.bottles[0].parallax.eq(a), 0.2, {
              opacity: 0.3,
              delay: 1.3,
            });
          }

          TweenMax.to(bottleWrapperObj.bottles[0].parallax.eq(a), 1, {
            "-webkit-clip-path": "inset(0px 0px 0px)",
            "clip-path": "inset(0px 0px 0px)",
            ease: Expo.easeOut,
            delay: factor_delay + a * factor,
          });
          bottleWrapperObj.bottles[0].parallax.eq(a).addClass("unmask");
        }

        for (
          var b = 0, len = bottleWrapperObj.bottles[0].parallaxSecond.length;
          b < len;
          b++
        ) {
          TweenMax.to(bottleWrapperObj.bottles[0].parallaxSecond.eq(b), 1, {
            y: 0,
            opacity: 1,
            delay: 1 + b * 0.3,
            ease: Power4.easeOut,
          });
        }
      }

      if (
        _scrollY > _globalViewportH &&
        _scrollY + _globalViewportH <
          bottleWrapperObj.offsetY + bottleWrapperObj.height
      ) {
        TweenMax.to($watermark, 1, {
          autoAlpha: 1,
        });

        for (var i = 0, len = bottleWrapperObj.bottles.length; i < len; i++) {
          //Check if slider is in viewport and animate small bottles
          var factor_alt = Math.abs(
              _scrollY - bottleWrapperObj.bottles[i].offsetY
            ),
            factor_opacity = Math.abs(
              _scrollY - bottleWrapperObj.bottles[i].offsetY
            ),
            normalizedFactor_alt =
              ((1 - 0) / (_globalViewportH - 0)) *
                (factor_alt - _globalViewportH) +
              1,
            normalizedFactor_opacity = 0;

          normalizedFactor_opacity = 1 - normalizedFactor_alt / 5;
          normalizedFactor_alt = Math.abs(20 * normalizedFactor_alt);

          if (normalizedFactor_opacity < 0.2) normalizedFactor_opacity = 0.15;

          TweenMax.to(bottleWrapperObj.bottlesList[i], 0.2, {
            x: normalizedFactor_alt + "px",
            opacity: normalizedFactor_opacity,
          });

          //Check what botlte is active
          if (
            _scrollY + _globalViewportH / 2 >
              bottleWrapperObj.bottles[i].offsetY &&
            _scrollY + _globalViewportH / 2 <
              bottleWrapperObj.bottles[i].bottomY &&
            !bottleWrapperObj.bottles[i].element.hasClass("js-active")
          ) {
            $bottleSlide.removeClass("js-active");
            $bottlesList.removeClass("js-active");
            bottleWrapperObj.bottles[i].element.addClass("js-active");
            bottleWrapperObj.bottlesList[i].addClass("js-active");
          }

          //Check if single slide is in viewport and animate bottle
          if (
            _scrollY > _globalViewportH &&
            _scrollY + _globalViewportH >=
              bottleWrapperObj.bottles[i].offsetY &&
            _scrollY <= bottleWrapperObj.bottles[i].bottomY
          ) {
            var boundingY = bottleWrapperObj.bottles[i].offsetY - _scrollY,
              factor =
                -_scrollY +
                bottleWrapperObj.bottles[i].offsetY +
                _globalViewportH / 2,
              normalizedFactor = 0;

            active_index = i;

            if (_scrollDirection == "down")
              normalizedFactor = map(
                factor,
                -_globalViewportH / 2,
                _globalViewportH + _globalViewportH / 2,
                0.5,
                1.5
              );
            else
              normalizedFactor = map(
                factor,
                _globalViewportH + _globalViewportH / 2,
                -_globalViewportH / 2,
                0.5,
                1.5
              );

            if (_globalViewportW > 1024) {
              TweenMax.to(bottleWrapperObj.bottles[i].photo, 0.6, {
                scale: normalizedFactor,
                y: boundingY * normalizedFactor * 0.4 + "px",
              });
            }

            // TweenMax.to(bottleWrapperObj.bottles[i].parallax, .3, {
            //   y: - boundingY*.1 + "px"
            // });

            // TweenMax.to(bottleWrapperObj.bottles[i].parallaxSecond, .3, {
            //   y: - boundingY*.1 + "px"
            // });

            //Check if scroll is somewhere inside 1/2 of the screen in the center
            if (
              _scrollY + _globalViewportH / 3 >
                bottleWrapperObj.bottles[i].offsetY &&
              _scrollY + (_globalViewportH * 3) / 4 <
                bottleWrapperObj.bottles[i].bottomY &&
              !bottleWrapperObj.bottles[i].parallax.hasClass("js-animated")
            ) {
              bottleWrapperObj.bottles[i].parallax.addClass("js-animated");

              for (
                var a = 0,
                  len = bottleWrapperObj.bottles[active_index].parallax.length;
                a < len;
                a++
              ) {
                bottleWrapperObj.bottles[active_index].parallax
                  .eq(a)
                  .addClass("delay-" + a);

                if (a == 0) {
                  var factor = 0.3;
                  var factor_delay = 0;
                } else {
                  var factor = 0.1;
                  var factor_delay = 0.25;
                }

                if (
                  bottleWrapperObj.bottles[active_index].parallax
                    .eq(a)
                    .hasClass("technical-btn")
                ) {
                  TweenMax.to(
                    bottleWrapperObj.bottles[active_index].parallax.eq(a),
                    0.2,
                    {
                      opacity: 0.3,
                      delay: 1.3,
                    }
                  );
                }

                TweenMax.to(
                  bottleWrapperObj.bottles[active_index].parallax.eq(a),
                  1,
                  {
                    "-webkit-clip-path": "inset(0px 0px 0px)",
                    "clip-path": "inset(0px 0px 0px)",
                    ease: Expo.easeOut,
                    delay: factor_delay + a * factor,
                  }
                );
                bottleWrapperObj.bottles[active_index].parallax
                  .eq(a)
                  .addClass("unmask");
              }

              for (
                var b = 0,
                  len =
                    bottleWrapperObj.bottles[active_index].parallaxSecond
                      .length;
                b < len;
                b++
              ) {
                TweenMax.to(
                  bottleWrapperObj.bottles[active_index].parallaxSecond.eq(b),
                  1,
                  {
                    y: 0,
                    opacity: 1,
                    delay: 1 + b * 0.3,
                    ease: Power4.easeOut,
                  }
                );
              }
            }
          }
        }
      } else {
        $bottleSlide.removeClass("js-active");
        $bottlesList.removeClass("js-active");
      }
    }

    var resize = function () {
      bottleWrapperObj = {};
      bottleWrapperObj.element = $bottleWrapper;
      bottleWrapperObj.offsetY = $bottleWrapper.offset().top + _scrollY;
      bottleWrapperObj.height = $bottleWrapper.height();
      bottleWrapperObj.bottles = [];
      bottleWrapperObj.bottlesList = [];

      $bottlesList.each(function (index) {
        var $this = $(this);

        bottleWrapperObj.bottlesList.push($this);
      });

      $bottleSlide.each(function (index) {
        var $this = $(this),
          elem = {};

        elem.element = $this;
        elem.photo = $this.find(".bottle-photo");
        elem.parallax = $this.find(".bottle-parallax");
        elem.parallaxSecond = $this.find(".bottle-parallax-second");
        elem.offsetY = $this.offset().top + _scrollY;
        elem.bottomY = $this.offset().top + $this.height() + _scrollY;

        bottleWrapperObj.bottles.push(elem);
      });

      $.doTimeout(500, function () {
        _blockAll = false;
      });
    };

    var kill = function () {
      TweenMax.set($bottleParallax, {
        "-webkit-clip-path": "inset(0px 0px 0px)",
        "clip-path": "inset(0px 0px 0px)",
      });
      TweenMax.set($bottleParallaxSecond, { y: 0, opacity: 1 });
      $bottleParallax.addClass("unmask");
      $bottleParallax.addClass("js-animated");
      TweenMax.set($(".technical-btn"), { opacity: 1 });
    };

    return {
      init: init,
      enter: enter,
      update: update,
      center: center,
      fixFirst: fixFirst,
      openBottles: openBottles,
      closeBottles: closeBottles,
      resize: resize,
      kill: kill,
    };
  }

  function reInit() {
    $(".bottle-parallax").each(function () {
      var $this = $(this);

      TweenMax.set($this, {
        "-webkit-clip-path": "inset(" + $this.outerHeight() + "px 0px 0px)",
        "clip-path": "inset(" + $this.outerHeight() + "px 0px 0px)",
      });
    });
    $(".bottle-parallax-second").each(function () {
      var $this = $(this);
      TweenMax.set($this, { y: 20, opacity: 0 });
    });
    TweenMax.set($(".technical-btn"), { opacity: 1 });
    bottle_list.openBottles();
  }

  function homePage_resize() {
    _blockAll = true;
    bottle_list.resize();
    var x = $(".ink-mask .bg-layer.show h3").offset(),
      marT = $(".ink-mask .bg-layer.show h3").css("marginTop"),
      h = $velhotesLogo.height(),
      r = parseInt(marT),
      bodyInit = $("body").attr("data-init");

    if (!bodyInit) {
      TweenMax.to($velhotesLogo, 0.4, {
        y: x.top - h * 5 - r * 1.3,
        force3D: true,
        ease: Expo.easeOut,
      });
    }

    if (_globalViewportW < 1024) {
      $(".legal-btn, footer a").addClass("hover-disabled");
      $(".bottle-photo").removeAttr("style");
      if (numOfResize == 0) {
        breakBySpace();
        numOfResize = 1;
      }
      bottle_list.kill();
    } else {
      if (
        $(".bottle-parallax").hasClass("unmask") &&
        $(".bottle-parallax").hasClass("js-animated")
      ) {
        return false;
      }
      reInit();
    }
  }

  function homePage_scroll() {
    _raf_scroll_id = _rAF_loop(homePage_scroll_rAF);
  }

  function homePage_scroll_rAF(status) {
    if (!$_html.hasClass("ie")) {
      TweenMax.set($bottleListContainer, { y: status.offset.y + "px" });
      // TweenMax.set($('.off-box'), { y : status.offset.y + 'px' });
      TweenMax.set($mark, { y: status.offset.y + "px" });
    }

    if ($_body.hasClass("phone")) {
      if (status.direction.y == "down" && verge.inViewport($thirdElem)) {
        TweenMax.to($mark, 1, { autoAlpha: 1, ease: Expo.easeOut });
      }
      if (
        status.direction.y == "down" &&
        verge.inViewport($quoteParent, -250)
      ) {
        TweenMax.to($mark, 0.6, { autoAlpha: 0, ease: Expo.easeOut });
      }
      if (status.direction.y == "up" && verge.inViewport($lastElem)) {
        TweenMax.to($mark, 1, { autoAlpha: 1, ease: Expo.easeOut });
      }
      if (
        status.direction.y == "up" &&
        verge.inViewport($scrollHelperParent, 100)
      ) {
        TweenMax.to($mark, 1, { autoAlpha: 0, ease: Expo.easeOut });
      }
    } else if ($_body.hasClass("mobile")) {
      if (status.direction.y == "down" && verge.inViewport($firstElem)) {
        TweenMax.to($mark, 1, { autoAlpha: 1, ease: Expo.easeOut, delay: 1.5 });
      }
      if (
        status.direction.y == "down" &&
        verge.inViewport($quoteParent, -150)
      ) {
        TweenMax.to($mark, 0.6, { autoAlpha: 0, ease: Expo.easeOut });
      }
      if (status.direction.y == "up" && verge.inViewport($lastElem)) {
        TweenMax.to($mark, 1, { autoAlpha: 1, ease: Expo.easeOut });
      }
      if (
        status.direction.y == "up" &&
        verge.inViewport($scrollHelperParent, 100)
      ) {
        TweenMax.to($mark, 1, { autoAlpha: 0, ease: Expo.easeOut });
      }
    }

    bottle_list.update(status);

    if (scrollTimeout !== null) {
      clearTimeout(scrollTimeout);
    }

    if (status.offset.y < _globalViewportH / 2 && status.direction.y == "up") {
      TweenMax.to($scrollHelperParent.find(".arrow-container"), 1, {
        opacity: 1,
        ease: Expo.easeOut,
      });
      TweenMax.staggerTo(
        $scrollHelperParent.find(".char"),
        2,
        { opacity: 1, ease: Expo.easeOut, yoyo: true, repeat: -1 },
        0.1
      );
    }

    if (status.offset.y < _globalViewportH && _globalViewportW > 1024) {
      bottle_list.fixFirst(status);
    }

    scrollTimeout = setTimeout(function () {
      bottle_list.center();
    }, 1000);
  }
} //////end function homePage
