function covidPage(do_covid) {
  if (!do_covid) {
    $_window.off("scroll.covidPage");
    $_window.off("resize.covidPage");
    return false;
  } else {
    if (_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      _scrollRef = function () {
        _raf_loop_id = _rAF_loop(covidPage_scroll_rAF);
      };
      $_window[0].addEventListener("scroll", _scrollRef, { passive: true });
    } else {
      _customScroll.registerEvents(/scroll/, /wheel/, /touchstart/);

      _page_scroll_listener = function (status) {
        covidPage_scroll_rAF(status);
      };

      _customScroll.addListener(_page_scroll_listener);
    }
  }

  /*variables*/
  var $headerMain = $("#header-main"),
    $table = $("table"),
    $menuBurguerOpen = $(".offmenu-btn .text.open"),
    $menuBurguerClose = $(".offmenu-btn .text.close"),
    $memeLinkVelhotes = $("#memes-velhotes"),
    $headerGlobal = $("#header-main .header-global"),
    $openMenu = $(".open-menu");

  initForm();

  if ($("body").hasClass("mobile")) {
    $(".page-header video source").attr(
      "src",
      $(".page-header video source").data("src-mobile")
    );
    $(".page-header video")[0].load();
  } else {
    $(".page-header video source").attr(
      "src",
      $(".page-header video source").data("src")
    );
    $(".page-header video")[0].load();
  }

  $(".main-navigation li.active").removeClass("active");
  breakTitleLetters($memeLinkVelhotes);
  breakTitleLetters($menuBurguerOpen);
  breakTitleLetters($menuBurguerClose);
  TweenMax.staggerTo(
    $memeLinkVelhotes.find(".char"),
    1.4,
    { y: 0, autoAlpha: 1, delay: 0.1, ease: Expo.easeOut },
    0.1
  );

  TweenMax.set($(".create-meme-link"), { autoAlpha: 1 });
  TweenMax.set($(".sort-by-btn"), { autoAlpha: 1 });
  TweenMax.set($("#create-meme-btn").parent().parent(), { autoAlpha: 1 });
  TweenMax.set("#create-meme-btn", { autoAlpha: 1 });
  TweenMax.set($openMenu, { autoAlpha: 1 });
  TweenMax.staggerTo(
    $openMenu.find(".text.open .char"),
    1.4,
    { y: 0, autoAlpha: 1, ease: Expo.easeOut },
    0.1
  );
  TweenMax.staggerTo(
    $openMenu.find(".burguer"),
    1.4,
    {
      x: "0%",
      autoAlpha: 1,
      ease: Expo.easeOut,
      onComplete: function () {
        $openMenu.find(".burguer-wrapper").removeClass("hidden");
        TweenMax.set($openMenu.find(".burguer"), { clearProps: "all" });
        $openMenu.css("pointer-events", "visible");
      },
    },
    0.1
  );

  TweenMax.set($(".create-meme-link"), { display: "none" });

  $table.each(function () {
    var $this = $(this);
    _customScroll = Scrollbar.init($this[0], {
      speed: 0.8,
      syncCallbacks: true,
    });
  });

  function initForm() {
    var $form = $(".donation-form");
    var $donateBtn = $(".donate");
    var $backBtn = $(".back");
    var $donationType = $(".donation-type");
    var $paypalBtn = $(".paypal-button");
    var $paypalForm = $(".paypal-form");
    var $radioWrapper = $(".radio-wrapper");

    addEvents();

    var validateForm = new Bouncer("form", {
      messages: {
        missingValue: {
          default: "Por favor preencha este campo.",
        },
      },
    });

    function inputErrorsAdd(ev) {
      var field = ev.target;
      field.closest(".input-wrapper").classList.add("error");
    }
    function inputErrorsRemove(ev) {
      var field = ev.target;
      field.closest(".input-wrapper").classList.remove("error");
    }

    function addEvents() {
      document.addEventListener("bouncerShowError", inputErrorsAdd, false);
      document.addEventListener("bouncerRemoveError", inputErrorsRemove, false);

      $radioWrapper.on("click", function () {
        $radioWrapper.removeClass("active");
        $(this).addClass("active");

        if (
          $(this).hasClass("iban-wrapper") ||
          $(this).hasClass("paypal-wrapper")
        ) {
          if ($(this).hasClass("paypal-wrapper")) {
            TweenMax.set($form.find("#name").parent(), {
              display: "none",
            });

            if (
              !$(".radio-wrapper.paypal-wrapper").hasClass("recipte-checked")
            ) {
              $form.find("#name").removeAttr("required");
              $form.find("#email").removeAttr("required");
              $form.find(".email-input").css({
                opacity: "0.5",
                pointerEvents: "none",
              });
            }
          } else {
            TweenMax.set($form.find("#name").parent(), {
              display: "block",
            });
            $form.find("#name").attr("required", true);
            $form.find("#email").attr("required", true);

            $form.find(".email-input").css({
              opacity: "",
              pointerEvents: "",
            });
          }
          TweenMax.set($form.find(".phase-1"), {
            display: "block",
          });
        } else {
          TweenMax.set($form.find(".phase-1"), {
            display: "none",
          });
        }
      });
      $(".page-header .scroll-btn").on("click", function () {
        _customScroll.scrollTo(
          0,
          $(".donation-form-wrapper").offset().top - 30,
          2000
        );
      });

      // $paypalBtn.on("click", function () {
      //   $(".paypal-form").submit();
      // });

      $form.find(".phase-1 #ammount").on("keyup", function () {
        $(this).val($(this).val().replace("€", "") + "€");
        ammount = $(this).val();
        $form.find("#ammount-mbway").val(ammount);
        $form.find("#ammount-paypal").val(ammount);
        $paypalForm.find(".paypal-amount").val($(this).val().replace("€", ""));

        if ($(this).val().length == 1) $(this).val("");

        if ($(this).val().length > 0) {
          $form.find(".phase-1 #ammount").parent().removeClass("error");
        }
      });
      $form.find(".phase-wrapper input").on("focus", function () {
        TweenMax.to($(this).parent().find("label"), 0.5, {
          y: -40,
          x: -35,
          scale: 0.9,
          ease: Power4.easeOut,
        });
      });

      $form.find("input").on("blur", function () {
        if ($(this).val() == "") {
          TweenMax.to($(this).parent().find("label"), 0.5, {
            y: 0,
            x: 0,
            scale: 1,
            ease: Power4.easeOut,
          });
        }
      });

      $form.find(".radio").on("click", function () {
        $form.find(".radio").parent().removeClass("active");
        $(this).parent().addClass("active");
      });

      $form.find(".recipte input").on("click", function () {
        if ($(this).prop("checked")) {
          $form.find(".recipte .hidden-recipte").val("Sim");
          $form.find(".phase-1 #nif").prop("required", true).addClass("error");

          $form.find(".nif-input").addClass("active");
          $form.find(".nif-input input").attr("required");
          $form.find(".nif-input input").removeAttr("tabindex");

          if ($(".radio-wrapper.paypal-wrapper").hasClass("active")) {
            $(".radio-wrapper.paypal-wrapper").addClass("recipte-checked");
            $form.find(".email-input input").attr("required", true);
            $form.find(".email-input").css({
              opacity: "",
              pointerEvents: "",
            });
          }
        } else {
          $form.find(".recipte .hidden-recipte").val("Não");
          $form
            .find(".phase-1 #nif")
            .prop("required", false)
            .removeClass("error");
          $form.find(".phase-1 #nif").parent().removeClass("error");
          $form.find(".nif-input").removeClass("active");
          $form.find(".nif-input input").removeAttr("required");
          $form.find(".nif-input input").attr("tabindex", "-1");

          if ($(".radio-wrapper.paypal-wrapper").hasClass("active")) {
            $(".radio-wrapper.paypal-wrapper").removeClass("recipte-checked");
            $form.find(".email-input input").removeAttr("required");
            $form.find(".email-input").css({
              opacity: "0.5",
              pointerEvents: "none",
            });
          }
        }
      });

      $donateBtn.on("click", function () {
        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();

        var isValid = validateForm.validateAll($form[0]);

        if ($form.find(".radio-wrapper.active").hasClass("mb-wrapper")) {
          gtag("event", "conversion", {
            allow_custom_scripts: true,
            send_to: "DC-9580574/invmedia/20204001+standard",
          });

          window.location.href = $(".mb-button").attr("href");
          return;
        } else if (
          $form.find(".radio-wrapper.active").hasClass("paypal-wrapper")
        ) {
          if (isValid.length == 0) {
            if (
              $(".radio-wrapper.paypal-wrapper").hasClass("recipte-checked")
            ) {
              gtag("event", "conversion", {
                allow_custom_scripts: true,
                send_to: "DC-9580574/invmedia/20204001+standard",
              });

              sendListPaypal();
              setTimeout(function () {
                $form[0].reset();
                $(".paypal-form").submit();
              }, 1000);
              return;
            } else {
              $form[0].reset();
              $(".paypal-form").submit();
              return;
            }
          }
        }

        // if ($form.find(".phase-1 #ammount").val().length <= 1) {
        //   $form.find(".phase-1 #ammount").parent().addClass("error");
        //   return;
        // }

        if (
          $form.find(".phase-1 #nif").prop("required") &&
          $form.find(".phase-1 #nif").val().length <= 1
        ) {
          $form.find(".phase-1 #nif").parent().addClass("error");
          return;
        }

        if (isValid.length != 0) {
          return;
        } else {
          gtag("event", "conversion", {
            allow_custom_scripts: true,
            send_to: "DC-9580574/invmedia/20204001+standard",
          });

          sendListIban();
          sendEmail();
        }

        if (
          $form.find(".phase-1 #email").hasClass("error") ||
          $form.find(".phase-1 #nif").hasClass("error")
        )
          return;

        var $checked = $donationType.find("input:checked");

        TweenMax.to(
          [
            $form.find(".form-header .phase-1-header"),
            $form.find(".phase-wrapper .phase-1"),
            $form.find(".form-footer .phase-1-footer"),
          ],
          0.5,
          {
            autoAlpha: 0,
            ease: Power4.easeOut,
          }
        );

        TweenMax.to(
          [
            $form.find(".form-header .phase-2-header"),
            $form.find(".form-footer .phase-2-footer"),
          ],
          0.5,
          {
            autoAlpha: 1,
            ease: Power4.easeOut,
            delay: 0.5,
          }
        );

        TweenMax.to($form.find(".radio-wrapper").not(".active"), 0.5, {
          opacity: 0.3,
          ease: Power4.easeOut,
          pointerEvents: "none",
          delay: 0.5,
        });

        if ($checked.hasClass("mbway-check")) {
          TweenMax.to($form.find(".form-header .mbway-message"), 0.5, {
            autoAlpha: 1,
            ease: Power4.easeOut,
            delay: 0.5,
          });
          TweenMax.to(
            $form.find(".phase-wrapper .phase-2 .mbway-fields"),
            0.5,
            {
              autoAlpha: 1,
              ease: Power4.easeOut,
              delay: 0.5,
            }
          );
        } else if ($checked.hasClass("paypal-check")) {
          TweenMax.to($form.find(".form-header .paypal-message"), 0.5, {
            autoAlpha: 1,
            ease: Power4.easeOut,
            delay: 0.5,
          });
          TweenMax.to(
            $form.find(".phase-wrapper .phase-2 .paypal-fields"),
            0.5,
            {
              autoAlpha: 1,
              ease: Power4.easeOut,
              delay: 0.5,
            }
          );
        } else if ($checked.hasClass("iban-check")) {
          TweenMax.to($form.find(".form-header .iban-message"), 0.5, {
            autoAlpha: 1,
            ease: Power4.easeOut,
            delay: 0.5,
          });
          TweenMax.to($form.find(".phase-wrapper .phase-2 .iban-fields"), 0.5, {
            autoAlpha: 1,
            ease: Power4.easeOut,
            delay: 0.5,
          });
        }
      });

      $backBtn.on("click", function () {
        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();

        var $checked = $donationType.find("input:checked");

        $form.find(".radio").parent().removeClass("active");

        $form.find(".radio").prop("checked", false);
        $form.find(".radio:first").addClass("active").prop("checked", true);
        $form.find(".radio-wrapper").removeClass("active");
        $form.find(".radio-wrapper:first").addClass("active");

        TweenMax.set($form.find(".phase-1"), {
          display: "none",
        });

        $form[0].reset();

        TweenMax.to(
          [
            $form.find(".form-header .phase-1-header"),
            $form.find(".phase-wrapper .phase-1"),
            $form.find(".form-footer .phase-1-footer"),
          ],
          0.5,
          {
            autoAlpha: 1,
            delay: 0.5,
            ease: Power4.easeOut,
          }
        );

        TweenMax.to(
          [
            $form.find(".form-header .phase-2-header"),
            $form.find(".form-footer .phase-2-footer"),
          ],
          0.5,
          {
            autoAlpha: 0,
            ease: Power4.easeOut,
          }
        );

        TweenMax.to($form.find(".radio-wrapper"), 0.5, {
          opacity: 1,
          ease: Power4.easeOut,
          pointerEvents: "visible",
        });

        if ($checked.hasClass("mbway-check")) {
          TweenMax.to($form.find(".form-header .mbway-message"), 0.5, {
            autoAlpha: 0,
            ease: Power4.easeOut,
          });
          TweenMax.to(
            $form.find(".phase-wrapper .phase-2 .mbway-fields"),
            0.5,
            {
              autoAlpha: 0,
              ease: Power4.easeOut,
            }
          );
        } else if ($checked.hasClass("paypal-check")) {
          TweenMax.to($form.find(".form-header .paypal-message"), 0.5, {
            autoAlpha: 0,
            ease: Power4.easeOut,
          });
          TweenMax.to(
            $form.find(".phase-wrapper .phase-2 .paypal-fields"),
            0.5,
            {
              autoAlpha: 0,
              ease: Power4.easeOut,
            }
          );
        } else if ($checked.hasClass("iban-check")) {
          TweenMax.to($form.find(".form-header .iban-message"), 0.5, {
            autoAlpha: 0,
            ease: Power4.easeOut,
          });
          TweenMax.to($form.find(".phase-wrapper .phase-2 .iban-fields"), 0.5, {
            autoAlpha: 0,
            ease: Power4.easeOut,
          });
        }
      });

      // $form.on("submit", function() {
      //   event.preventDefault();
      //   event.stopImmediatePropagation();
      //   event.stopPropagation();

      //   if ($donateBtn.hasClass("iban-donate")) {
      //     sendListIban();
      //     sendEmail();
      //     console.log("submete o form");
      //   }

      //   return false;
      // });

      function sendListIban() {
        var formData = {
          list_ids: ["3cca0722-348f-4e73-b2dd-10d3687f4d85"],
          contacts: [
            {
              email: $form.find(".phase-1 #email").val(),
              custom_fields: {
                e1_T: $form.find(".phase-1 #ammount").val(),
                e2_T: !$form.find(".phase-1 #name").val()
                  ? "N/A"
                  : $form.find(".phase-1 #name").val(),
                e3_T: !$form.find(".phase-1 #nif").val()
                  ? "N/A"
                  : $form.find(".phase-1 #nif").val(),
              },
            },
          ],
        };

        var options = {
          async: true,
          crossDomain: true,
          url: "https://api.sendgrid.com/v3/marketing/contacts",
          method: "PUT",
          headers: {
            Authorization:
              "Bearer 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",
            "Content-Type": "application/json",
          },
          data: JSON.stringify(formData),
        };

        $.ajax(options)
          .then((response) => {
            // console.log("enviado", response);
          })
          .catch((error) => {
            // console.log("erro", error);
          });
      }

      function sendListPaypal() {
        var formData = {
          list_ids: ["eca94519-7cb1-4374-b10b-10935f58f04b"],
          contacts: [
            {
              email: $form.find(".phase-1 #email").val(),
              custom_fields: {
                e1_T: $form.find(".phase-1 #ammount").val(),
                e3_T: $form.find(".phase-1 #nif").val(),
              },
            },
          ],
        };

        var options = {
          async: true,
          crossDomain: true,
          url: "https://api.sendgrid.com/v3/marketing/contacts",
          method: "PUT",
          headers: {
            Authorization:
              "Bearer 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",
            "Content-Type": "application/json",
          },
          data: JSON.stringify(formData),
        };

        $.ajax(options)
          .then((response) => {
            // console.log("enviado", response);
          })
          .catch((error) => {
            // console.log("erro", error);
          });
      }

      function sendEmail() {
        $.ajax({
          type: "post",
          url: "/send-mail.php",
          data: $form.serialize(),
          success: function (data) {
            // $backBtn.click();
            // $form[0].reset();
          },
          error: function (data) {},
        });
      }
    }
  }

  function covidPage_scroll_rAF(status) {
    // if (!$_html.hasClass("ie")) {
    //   $headerGlobal.css({ transform: "translateY(" + status.offset.y + "px)" });
    //   if (_scrollY > 0 && !$headerGlobal.hasClass("active")) {
    //     TweenMax.set($headerMain.find(".header-global"), {
    //       className: "+=active"
    //     });
    //     $headerGlobal.addClass("active");
    //   } else if (_scrollY == 1 && $headerGlobal.hasClass("active")) {
    //     $headerGlobal.removeClass("active");
    //   }
    // }
  }
} //////end function homePage

$.fn.setCursorPosition = function (pos) {
  this.each(function (index, elem) {
    if (elem.setSelectionRange) {
      elem.setSelectionRange(pos, pos);
    } else if (elem.createTextRange) {
      var range = elem.createTextRange();
      range.collapse(true);
      range.moveEnd("character", pos);
      range.moveStart("character", pos);
      range.select();
    }
  });
  return this;
};
