function cocktailsPage(do_cocktails) {
  if (!do_cocktails) {
    $_window.off("scroll.cocktailsPage");
    $_window.off("resize.cocktailsPage");
    return false;
  } else {
    if (_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      _scrollRef = function () {
        _raf_loop_id = _rAF_loop(cocktailsPage_scroll_rAF);
      };
      $_window[0].addEventListener("scroll", _scrollRef, { passive: true });
    } else {
      _customScroll.registerEvents(/scroll/, /wheel/, /touchstart/);

      _page_scroll_listener = function (status) {
        cocktailsPage_scroll_rAF(status);
      };

      _customScroll.addListener(_page_scroll_listener);
    }
  }

  /*variables*/
  var $headerMain = $("#header-main"),
    $table = $("table"),
    $menuBurguerOpen = $(".offmenu-btn .text.open"),
    $menuBurguerClose = $(".offmenu-btn .text.close"),
    $memeLinkVelhotes = $("#memes-velhotes"),
    $headerGlobal = $("#header-main .header-global"),
    $homePageLink = $(".homepage-link h2"),
    $scrollHelper = $(".scroll-helper h2"),
    $velhotesLogo = $(".velhotes-logo"),
    $memeLinkCria = $("#cria-o-meme"),
    $mask = $(".ink-mask"),
    $yesBtn = $("#yes"),
    $openMenu = $(".open-menu");

  breakTitleLetters($homePageLink);
  breakTitleLetters($menuBurguerOpen);
  breakTitleLetters($menuBurguerClose);

  $(".offmenu .main-navigation li").removeClass("active");

  TweenMax.set($homePageLink, { autoAlpha: 1 });
  $(".homepage-link").addClass("showHouse");
  $velhotesLogo.off("click");
  TweenMax.staggerTo(
    $homePageLink.find(".char"),
    1.4,
    { y: 0, autoAlpha: 1, ease: Expo.easeOut },
    0.1
  );

  if (cookie.get("age-check") === "true") {
    TweenMax.set($openMenu, { autoAlpha: 1 });
    TweenMax.staggerTo(
      $openMenu.find(".text.open .char"),
      1.4,
      { y: 0, autoAlpha: 1, ease: Expo.easeOut },
      0.1
    );
    TweenMax.staggerTo(
      $openMenu.find(".burguer"),
      1.4,
      {
        x: "0%",
        autoAlpha: 1,
        ease: Expo.easeOut,
        onComplete: function () {
          $openMenu.find(".burguer-wrapper").removeClass("hidden");
          TweenMax.set($openMenu.find(".burguer"), { clearProps: "all" });
          $openMenu.css("pointer-events", "visible");
        },
      },
      0.1
    );
  }

  if (cookie.get("age-check") === "false") {
    _customScroll.unregisterEvents(/scroll/, /wheel/, /touchstart/);

    TweenMax.to($(".velhotes-logo"), 1, {
      autoAlpha: 1,
      ease: Expo.easeOut,
    });
  }

  $yesBtn.on("click", function () {
    $mask.addClass("in");

    $("#header-main").addClass("active");
    $("#header-main .letters").addClass("add-transition");

    TweenMax.to($(".velhotes-logo"), 0.8, {
      autoAlpha: 0,
      ease: Expo.easeOut,
    });

    $.doTimeout(500, function () {
      TweenMax.set($openMenu, { autoAlpha: 1 });
      TweenMax.staggerTo(
        $openMenu.find(".text.open .char"),
        1.4,
        { y: 0, autoAlpha: 1, ease: Expo.easeOut },
        0.1
      );
      TweenMax.staggerTo(
        $openMenu.find(".burguer"),
        1.4,
        {
          x: "0%",
          autoAlpha: 1,
          ease: Expo.easeOut,
          onComplete: function () {
            $openMenu.find(".burguer-wrapper").removeClass("hidden");
            TweenMax.set($openMenu.find(".burguer"), { clearProps: "all" });
            $openMenu.css("pointer-events", "visible");
          },
        },
        0.1
      );
    });

    $.doTimeout(2000, function () {
      $mask.hide();
      _customScroll.registerEvents(/scroll/, /wheel/, /touchstart/);
    });
  });

  function cocktailsPage_scroll_rAF(status) {}
} //////end function homePage

$.fn.setCursorPosition = function (pos) {
  this.each(function (index, elem) {
    if (elem.setSelectionRange) {
      elem.setSelectionRange(pos, pos);
    } else if (elem.createTextRange) {
      var range = elem.createTextRange();
      range.collapse(true);
      range.moveEnd("character", pos);
      range.moveStart("character", pos);
      range.select();
    }
  });
  return this;
};
