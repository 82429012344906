function legalPage(do_legal) {
  if (!do_legal) {
   $_window.off("scroll.legalPage");
   $_window.off("resize.legalPage");
   return false;
  }
  else {

    if(_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      _scrollRef = function() { _raf_loop_id = _rAF_loop( legalPage_scroll_rAF ); }
      $_window[0].addEventListener("scroll", _scrollRef, { passive: true } );
    }
    else {
      _customScroll.registerEvents(/scroll/, /wheel/, /touchstart/);

      _page_scroll_listener = function(status) {
        legalPage_scroll_rAF(status);
      };

      _customScroll.addListener(_page_scroll_listener);
    }
  }


  /*variables*/
  var $headerMain = $("#header-main"),
      $table = $("table"),
      $menuBurguerOpen = $(".offmenu-btn .text.open"),
      $menuBurguerClose = $(".offmenu-btn .text.close"),
      $memeLinkVelhotes = $("#memes-velhotes"),
      $headerGlobal = $("#header-main .header-global"),
      $openMenu = $(".open-menu");

  breakTitleLetters($memeLinkVelhotes);
  breakTitleLetters($menuBurguerOpen);
  breakTitleLetters($menuBurguerClose);
  TweenMax.staggerTo($memeLinkVelhotes.find(".char"), 1.4, {y: 0, autoAlpha: 1, delay: 0.1, ease: Expo.easeOut}, 0.1);

  if (cookie.get('age-check') == 'true') {
    TweenMax.set($(".create-meme-link"), {autoAlpha: 1});
    TweenMax.set($(".sort-by-btn"), {autoAlpha: 1});
    TweenMax.set($("#create-meme-btn").parent().parent(), {autoAlpha:1});
    TweenMax.set("#create-meme-btn", {autoAlpha:1});
    TweenMax.set($openMenu, {autoAlpha:1});
    TweenMax.staggerTo($openMenu.find(".text.open .char"), 1.4, {y: 0, autoAlpha: 1, ease: Expo.easeOut}, 0.1);
    TweenMax.staggerTo($openMenu.find(".burguer"), 1.4, {
      x: "0%",
      autoAlpha: 1,
      ease: Expo.easeOut,
      onComplete: function() {
        $openMenu.find(".burguer-wrapper").removeClass("hidden");
        TweenMax.set($openMenu.find(".burguer"), {clearProps: "all"});
        $openMenu.css("pointer-events", "visible");
      }
    }, 0.1);
  }

  $(".create-meme-link").attr("data-remote", "false");
  $table.each(function(){
    var $this = $(this);
    _customScroll = Scrollbar.init($this[0], { speed: 0.8, syncCallbacks: true });
  });

  function legalPage_scroll_rAF(status) {
   if (!$_html.hasClass("ie")) {
     // if(!$_body.hasClass("phone")) {
     //   TweenMax.to($headerMain.find(".header-global"), 1, {y: 6, force3D:true, ease: Expo.easeOut, className:"+=active"});
     // } else {
     //   TweenMax.to($headerMain.find(".header-global"), 1, {y: 0, force3D:true, ease: Expo.easeOut, className:"+=active"});
     // }

     // TweenMax.set($headerMain.find(".header-global"), {position: "fixed", backgroundColor: "rgba(255,255,255,0.97)", boxShadow: "0px 2px 12px 0px rgba(133, 117, 77, 0.2)"});
     // $headerGlobal.css({"top" : status.offset.y +"px"});

    $headerGlobal.css({"top" : status.offset.y +"px"});
    if(_scrollY > 0 && !$headerGlobal.hasClass("active")) {
      TweenMax.set($headerMain.find(".header-global"), {
        className:"+=active"
      });
      $headerGlobal.addClass("active");
    } else if(_scrollY == 1 && $headerGlobal.hasClass("active")) {
      $headerGlobal.removeClass("active");
    }
   }
  }

} //////end function homePage