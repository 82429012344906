function legadoPage(do_legado) {
  if (!do_legado) {
   $_window.off("scroll.legadoPage");
   $_window.off("resize.legadoPage");
   return false;
  }
  else {

    if(_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      _scrollRef = function() { _raf_loop_id = _rAF_loop( legadoPage_scroll_rAF ); }
      $_window[0].addEventListener("scroll", _scrollRef, { passive: true } );
    }
    else {
      _customScroll.unregisterEvents(/scroll/, /wheel/, /touchstart/);

      _page_scroll_listener = function(status) {
        legadoPage_scroll_rAF(status);
      };

      _customScroll.addListener(_page_scroll_listener);
    }
  }

  var app = null,
      velhoteCenterSprite = null,
      velhoteEsqSprite = null,
      velhoteDirSprite = null,
      velhotes_raf = null,
      velhoteCenterFirstSprite = null,
      velhoteEsqFirstSprite = null
      velhoteDirFirstSprite = null;

  /*variables*/
  var $headerMain = $("#header-main"),
      $offMenu = $(".offmenu"),
      $mainNavigation = $offMenu.find(".main-navigation"),
      $headerGlobal = $("#header-main .header-global"),
      $memeLinkVelhotes = $("#memes-velhotes"),
      $memeLinkCria = $("#cria-o-meme"),
      $homePageLink = $(".homepage-link h2"),
      $sloganHeader = $(".page-header .slogan"),
      $sloganFooter = $(".page-footer .slogan"),
      $scrollHelper = $(".scroll-helper h2"),
      $yesBtn = $("#yes"),
      $mask = $('.ink-mask'),
      $menuBurguerOpen = $(".offmenu-btn .text.open"),
      $menuBurguerClose = $(".offmenu-btn .text.close"),
      $arriveSection = $(".arrive"),
      $frameSection = $(".frame-legacy"),
      $openMenu = $(".open-menu"),
      $bottleParallaxSecond = $(".bottle-parallax-second"),
      $videoAnime = $(".video-anime"),
      $imageAnime = $(".js-image-anime"),
      $timeline = $(".timeline"),
      $timelineText = $timeline.find(".anime-text"),
      $timelineFrame = $timeline.find(".frame-svg"),
      $timelineStartPoint = $timeline.find(".start-point"),
      timelineAnimation = null,
      allJSONLoaded = false,
      amsterdam_video = null,
      timeline_offset = Number($timeline.offset().top.toFixed(0)),
      _currentYear = new Date().getFullYear(),
      _offsetTopMiddlePoint = $timeline.find(".middle-point").offset().top,
      $feedbackContainer = $(".feedback-container"),
      $offBox = $(".off-box"),
      logo_offset_top = $(".velhotes-logo").offset().top,
      $controllerBox = $(".controller-box"),
      $velhotesLogo = $(".velhotes-logo"),
      loveAttractionObj = loveAttraction({
        target: $(".js-parallax"),
        factor: 0.025
      });

      _scrollAnimations = initScrollAnimations();

      velhote_left_obj = {
        jsonPath: '/public/json/velhote-left-high.json',
        jsonData: null,
        element: $(".velhote-left-json"),
        currentFrame: 0,
        totalFrames: 82,
        playAnimation: false,
        finished: false
      };

      velhote_center_obj = {
        jsonPath: '/public/json/velhote-center-high.json',
        jsonData: null,
        element: $(".velhote-center-json"),
        currentFrame: 0,
        totalFrames: 73,
        playAnimation: false,
        finished: false
      };

      velhote_right_obj = {
        jsonPath: '/public/json/velhote-right-high.json',
        jsonData: null,
        element: $(".velhote-right-json"),
        currentFrame: 0,
        totalFrames: 72,
        playAnimation: false,
        finished: false
      };

  /*inits*/
  breakTitleLetters($homePageLink);
  breakTitleLetters($menuBurguerOpen);
  breakTitleLetters($menuBurguerClose);
  breakTitleLetters($scrollHelper);
  TweenMax.set($homePageLink, {autoAlpha: 1});
  $(".homepage-link").addClass("showHouse");
  $velhotesLogo.off('click');
  TweenMax.staggerTo($homePageLink.find(".char"), 1.4, {y: 0, autoAlpha: 1, ease: Expo.easeOut}, 0.1);
  TweenMax.staggerTo($memeLinkCria.find(".char"), 1.4, {y: 0, autoAlpha: 1, delay: 0.1, ease: Expo.easeOut}, 0.1);
  TweenMax.staggerTo($memeLinkVelhotes.find(".char"), 0.4, {y: 100, autoAlpha: 1, ease: Expo.easeIn}, 0.1);

  // $("#loading-page-legado").addClass("js-loading-page");

  var feedBackHeight = $feedbackContainer.find("h3").height();
  $feedbackContainer.css("height", feedBackHeight);

  if(!$mainNavigation.find("li").eq(1).hasClass("active")) {
    $mainNavigation.find("li").removeClass("active");
    $mainNavigation.find("li").eq(1).addClass("active");
  }

  if(!$_body.hasClass("mobile")){
    var vid = document.getElementById("videoFirst");
        vid.load();

    vid.addEventListener('canplay', function () {
      vid.play();
      /// Execute other events
      $.doTimeout(1000, function () {
        if($_body.hasClass("tablet")) {
          $arriveSection.css("height", $arriveSection.find("img").height());
        } else {
          $arriveSection.css("height", $arriveSection.find("video").height());
        }

        _scrollAnimations.init();
        _customScroll.registerEvents(/scroll/, /wheel/, /touchstart/);
        timeline_offset = $timeline.offset().top;
        $timeline.css("opacity", 1);
      });

      $(".page-header").css("height", $(".page-header").find("img").height());
      $(".frame-legacy .pattern-img").css("height", $(".frame-legacy video").height());

      $sloganHeader.addClass("change-spacing");

      loveAttractionObj.init();

      $imageAnime.each(function() {
        var $this = $(this);

        if($this.attr("data-animeTo") == "left") {
          TweenMax.set($this, { x: 50 });
        }
        if($this.attr("data-animeTo") == "right") {
          TweenMax.set($this, { x: -50 });
        }

        if(verge.inViewport($this) && !$this.hasClass("js-anime")) {
          $this.addClass("js-anime");

          TweenMax.to($this, 1.5, {
            x: 0,
            opacity: 1,
            delay: .2,
            ease: Power2.easeOut
          });
        }
      });

      initVelhotesAnimation();
    });
  } else {
    $.doTimeout(1000, function () {
      if($_body.hasClass("tablet")) {
        $arriveSection.css("height", $arriveSection.find("img").height());
      } else {
        $arriveSection.css("height", $arriveSection.find("video").height());
      }

      _scrollAnimations.init();
      _customScroll.registerEvents(/scroll/, /wheel/, /touchstart/);
      timeline_offset = $timeline.offset().top;
      $timeline.css("opacity", 1);
    });

    $(".page-header").css("height", $(".page-header").find("img").height());
    $(".frame-legacy .pattern-img").css("height", $(".frame-legacy video").height());

    $sloganHeader.addClass("change-spacing");

    $imageAnime.each(function() {
      var $this = $(this);

      if($this.attr("data-animeTo") == "left") {
        TweenMax.set($this, { x: 50 });
      }
      if($this.attr("data-animeTo") == "right") {
        TweenMax.set($this, { x: -50 });
      }

      if(verge.inViewport($this) && !$this.hasClass("js-anime")) {
        $this.addClass("js-anime");

        TweenMax.to($this, 1.5, {
          x: 0,
          opacity: 1,
          delay: .2,
          ease: Power2.easeOut
        });
      }
    });
  }

  function initVelhotesAnimation() {
    var fps = 30;
    var now;
    var then = Date.now();
    var interval = 1000/fps;
    var delta;
    var velhote_esq_controls = {
          current_frame : 0,
          total_frames: 83,
          in_frame: 42,
          can_animate: false,
          go_to_end: false
        },
        velhote_center_controls = {
          current_frame : 0,
          total_frames: 74,
          in_frame: 40,
          can_animate: false,
          go_to_end: false
        },
        velhote_right_controls = {
          current_frame : 0,
          total_frames: 73,
          in_frame: 37,
          can_animate: false,
          go_to_end: false
        };

    $(".frame-legacy .js-hover").on("mouseenter", function() {
      var $this = $(this);

      if($this.hasClass('left-man-area')) {
        velhote_esq_controls.can_animate = true;
        velhote_esq_controls.go_to_end = false;
      }
      if($this.hasClass('middle-man-area')) {
        velhote_center_controls.can_animate = true;
        velhote_center_controls.go_to_end = false;
      }
      if($this.hasClass('right-man-area')) {
        velhote_right_controls.can_animate = true;
        velhote_right_controls.go_to_end = false;
      }
    });

    $(".frame-legacy .js-hover").on("mouseleave", function() {
      var $this = $(this);

      if($this.hasClass('left-man-area')) {
        velhote_esq_controls.can_animate = false;
        velhote_esq_controls.go_to_end = true;
      }
      if($this.hasClass('middle-man-area')) {
        velhote_center_controls.can_animate = false;
        velhote_center_controls.go_to_end = true;
      }
      if($this.hasClass('right-man-area')) {
        velhote_right_controls.can_animate = false;
        velhote_right_controls.go_to_end = true;
      }
    });

    var velhoteEsqTextures = [],
        velhoteCenterTextures = [],
        velhoteDirTextures = [];

    app = new PIXI.Application($frameSection.find(".frame-wrapper").width(), $frameSection.find(".frame-wrapper").height(), { transparent: true, forceCanvas: true });

    $frameSection.find(".frame-wrapper").append(app.view);

    app.stop();

    var loader_first_frame = new PIXI.loaders.Loader();

    loader_first_frame.add('velhote-esq-0' , '/public/imgs/legado/frame/velhote-esq/velhote-esq-0.png');
    loader_first_frame.add('velhote-center-0' , '/public/imgs/legado/frame/velhote-center/velhote-center-0.png');
    loader_first_frame.add('velhote-dt-0' , '/public/imgs/legado/frame/velhote-dt/velhote-dt-0.png');
    loader_first_frame.add('book-isolated-canvas' , '/public/imgs/legado/frame/book-isolated-canvas.png');
    loader_first_frame.add('mesa-isolated-canvas.png' , '/public/imgs/legado/frame/mesa-isolated-canvas.png');

    loader_first_frame.load(function() {
      // create an array to store the textures
      var velhoteEsqTextures = [],
          velhoteCenterTextures = [],
          velhoteDirTextures = [];

      var velhote = PIXI.Texture.fromFrame('velhote-esq-0');
      velhoteEsqTextures.push(velhote);
      var velhote = PIXI.Texture.fromFrame('velhote-center-0');
      velhoteCenterTextures.push(velhote);
      var velhote = PIXI.Texture.fromFrame('velhote-dt-0');
      velhoteDirTextures.push(velhote);

      ////// Livro
      var book = PIXI.Sprite.fromImage('/public/imgs/legado/frame/book-isolated-canvas.png');

      book.x = -79;
      book.y = -61;
      book.width = $frameSection.find(".book-isolated").width();
      book.height = $frameSection.find(".book-isolated").height();

      book.anchor.set(0);
      ///////////////////////////////

      ////// Mesa
      var table = PIXI.Sprite.fromImage('/public/imgs/legado/frame/mesa-isolated-canvas.png');

      table.x = -79;
      table.y = -61;
      table.width = $frameSection.find(".mesa-isolated").width();
      table.height = $frameSection.find(".mesa-isolated").height();

      table.anchor.set(0);

      app.stage.addChild(table);
      ///////////////////////////////

      //// Velhote Centro
      velhoteCenterFirstSprite = new PIXI.extras.AnimatedSprite(velhoteCenterTextures);

      velhoteCenterFirstSprite.x = 0;
      velhoteCenterFirstSprite.y = 0;
      velhoteCenterFirstSprite.anchor.set(0);

      velhoteCenterFirstSprite.width = $frameSection.find(".velhote-center").width();
      velhoteCenterFirstSprite.height = $frameSection.find(".velhote-center").height();

      app.stage.addChild(velhoteCenterFirstSprite);
      ////////////////////////////////

      //// Velhote Esquerdo
      velhoteEsqFirstSprite = new PIXI.extras.AnimatedSprite(velhoteEsqTextures);
 
      velhoteEsqFirstSprite.x = 0; 
      velhoteEsqFirstSprite.y = 0;
      velhoteEsqFirstSprite.anchor.set(0);

      velhoteEsqFirstSprite.width = $frameSection.find(".velhote-left").width();
      velhoteEsqFirstSprite.height = $frameSection.find(".velhote-left").height();

      app.stage.addChild(velhoteEsqFirstSprite);
      ////////////////////////////////

      //// Velhote Direito
      velhoteDirFirstSprite = new PIXI.extras.AnimatedSprite(velhoteDirTextures);

      velhoteDirFirstSprite.x = 0;
      velhoteDirFirstSprite.y = 0;
      velhoteDirFirstSprite.anchor.set(0);

      velhoteDirFirstSprite.width = $frameSection.find(".velhote-right").width();
      velhoteDirFirstSprite.height = $frameSection.find(".velhote-right").height();

      app.stage.addChild(velhoteDirFirstSprite);
      ////////////////////////////////

      app.stage.addChild(book);

      app.start();

      ////////////////////////////////
      var loader = new PIXI.loaders.Loader();

      for (i = 0; i < velhote_esq_controls.in_frame; i++) {
        loader.add('velhote-esq-' + i , '/public/imgs/legado/frame/velhote-esq/velhote-esq-' + i + '.png');
      }
      for (i = 0; i < velhote_center_controls.in_frame; i++) {
        loader.add('velhote-center-' + i , '/public/imgs/legado/frame/velhote-center/velhote-center-' + i + '.png');
      }
      for (i = 0; i < velhote_right_controls.in_frame; i++) {
        loader.add('velhote-dt-' + i , '/public/imgs/legado/frame/velhote-dt/velhote-dt-' + i + '.png');
      }

      loader.load(function() {
         onAssetsLoaded();
      });
    });

    function onAssetsLoaded() {
      for (var i = 0; i < velhote_esq_controls.in_frame; i++) {
         var velhote = PIXI.Texture.fromFrame('velhote-esq-' + i);
         velhote.baseTexture.mipmap = true;
         velhoteEsqTextures.push(velhote);
      }
      for (var i = 0; i < velhote_center_controls.in_frame; i++) {
         var velhote = PIXI.Texture.fromFrame('velhote-center-' + i);
         velhote.baseTexture.mipmap = true;
         velhoteCenterTextures.push(velhote);
      }
      for (var i = 0; i < velhote_right_controls.in_frame; i++) {
         var velhote = PIXI.Texture.fromFrame('velhote-dt-' + i);
         velhote.baseTexture.mipmap = true;
         velhoteDirTextures.push(velhote);
      }

      ////// Livro
      var book = PIXI.Sprite.fromImage('/public/imgs/legado/frame/book-isolated-canvas.png');

      book.x = -79;
      book.y = -61;
      book.width = $frameSection.find(".book-isolated").width();
      book.height = $frameSection.find(".book-isolated").height();

      book.anchor.set(0);
      ///////////////////////////////

      ////// Mesa
      var table = PIXI.Sprite.fromImage('/public/imgs/legado/frame/mesa-isolated-canvas.png');

      table.x = -79;
      table.y = -61;
      table.width = $frameSection.find(".mesa-isolated").width();
      table.height = $frameSection.find(".mesa-isolated").height();

      table.anchor.set(0);

      app.stage.addChild(table);
      ///////////////////////////////

      //// Velhote Centro
      velhoteCenterSprite = new PIXI.extras.AnimatedSprite(velhoteCenterTextures);

      velhoteCenterSprite.x = 0;
      velhoteCenterSprite.y = 0;
      velhoteCenterSprite.width = $frameSection.find(".velhote-center").width();
      velhoteCenterSprite.height = $frameSection.find(".velhote-center").height();

      velhoteCenterSprite.interactive = true;
      velhoteCenterSprite.buttonMode = true;
      velhoteCenterSprite.anchor.set(0);
      velhoteCenterSprite.type = 'velhote-center';

      app.stage.addChild(velhoteCenterSprite);
      ////////////////////////////////

      //// Velhote Esquerdo
      velhoteEsqSprite = new PIXI.extras.AnimatedSprite(velhoteEsqTextures);

      velhoteEsqSprite.x = 0;
      velhoteEsqSprite.y = 0;
      velhoteEsqSprite.width = $frameSection.find(".velhote-left").width();
      velhoteEsqSprite.height = $frameSection.find(".velhote-left").height();

      velhoteEsqSprite.interactive = true;
      velhoteEsqSprite.buttonMode = true;
      velhoteEsqSprite.anchor.set(0);
      velhoteEsqSprite.type = 'velhote-esq';

      app.stage.addChild(velhoteEsqSprite);
      ////////////////////////////////

      //// Velhote Direito
      velhoteDirSprite = new PIXI.extras.AnimatedSprite(velhoteDirTextures);

      velhoteDirSprite.x = 0;
      velhoteDirSprite.y = 0;
      velhoteDirSprite.width = $frameSection.find(".velhote-right").width();
      velhoteDirSprite.height = $frameSection.find(".velhote-right").height();

      velhoteDirSprite.interactive = true;
      velhoteDirSprite.buttonMode = true;
      velhoteDirSprite.anchor.set(0);
      velhoteDirSprite.type = 'velhote-dir';

      app.stage.addChild(velhoteDirSprite);
      ////////////////////////////////

      app.stage.addChild(book);

      velhotes_raf = _rAF_loop( animationLoop );
      animationLoop();

      ////////////////////////////////
      app.stage.removeChild(velhoteEsqFirstSprite);
      app.stage.removeChild(velhoteCenterFirstSprite);
      app.stage.removeChild(velhoteDirFirstSprite);
      velhoteEsqFirstSprite = null;
      velhoteCenterFirstSprite = null;
      velhoteDirFirstSprite = null;
      ////////////////////////////////

      function animationLoop() {
        velhotes_raf = _rAF_loop( animationLoop );

        now = Date.now();
        delta = now - then;

        if (delta > interval) {
            then = now - (delta % interval);

          if(velhote_esq_controls.can_animate) {
            if(velhote_esq_controls.current_frame < velhote_esq_controls.in_frame) {
              velhoteEsqSprite.gotoAndStop(velhote_esq_controls.current_frame++);
            }
          }
          else {
            if(velhote_esq_controls.current_frame > 0) {
              velhoteEsqSprite.gotoAndStop(velhote_esq_controls.current_frame--);
            }
          }

          if(velhote_center_controls.can_animate) {
            if(velhote_center_controls.current_frame < velhote_center_controls.in_frame) {
              velhoteCenterSprite.gotoAndStop(velhote_center_controls.current_frame++);
            }
          }
          else {
            if(velhote_center_controls.current_frame > 0) {
              velhoteCenterSprite.gotoAndStop(velhote_center_controls.current_frame--);
            }
          }

          if(velhote_right_controls.can_animate) {
            if(velhote_right_controls.current_frame < velhote_right_controls.in_frame) {
              velhoteDirSprite.gotoAndStop(velhote_right_controls.current_frame++);
            }
          }
          else {
            if(velhote_right_controls.current_frame > 0) {
              velhoteDirSprite.gotoAndStop(velhote_right_controls.current_frame--);
            }
          }
        }
      }
    }
  }

  $offBox.find(".close-box").on("click", function() {
    $offBox.removeClass("js-anime");
    TweenMax.to($offBox, 1, {
      x: "100%",
      opacity: 0,
      ease: Power4.easeOut
    });
  });

  $bottleParallaxSecond.each(function() {
    var $this = $(this);

    TweenMax.set($this, { y: 20, opacity: 0 });
    if(verge.inViewport($this) && !$this.hasClass("js-anime")) {
      $this.addClass("js-anime");

      TweenMax.to($this, 1, {
        y: 0,
        opacity: 1,
        ease: Power4.easeOut
      });
    }
  });

  var bezierData = MorphSVGPlugin.pathDataToBezier(".line-complete", {offsetX:-15, offsetY:-15, align:".pointer"}),
      bezierDataText = MorphSVGPlugin.pathDataToBezier(".line-complete", {offsetX:30, offsetY:-10, align:".anime-text"});

  timelineAnimation = TweenMax.to(".pointer", 2, {
    bezier: {
      values: bezierData,
      type: "cubic"
    },
    paused: true,
    ease: Linear.easeNone
  });

  timelineAnimationText = TweenMax.to(".anime-text", 2, {
    bezier: {
      values: bezierDataText,
      type: "cubic"
    },
    paused: true,
    ease: Linear.easeNone
  });

  TweenMax.to($(".arrow-container"), 2, {opacity: 1, ease: Expo.easeOut});
  TweenMax.set($(".scroll-helper"), {autoAlpha: 1});
  TweenMax.set($(".scroll-helper").find(".char"), {opacity: 1});

  if (cookie.get('age-check') == 'true') {
    TweenMax.set($(".create-meme-link"), {autoAlpha: 1});
    TweenMax.set($(".sort-by-btn"), {autoAlpha: 1});
    TweenMax.set($memeLinkCria, {autoAlpha: 1});
    // TweenMax.set($("#create-meme-btn").parent().parent(), {autoAlpha:1});
    TweenMax.set("#create-meme-btn", {autoAlpha:1});
    TweenMax.set($openMenu, {autoAlpha:1});
    TweenMax.staggerTo($openMenu.find(".text.open .char"), 1.4, {y: 0, autoAlpha: 1, ease: Expo.easeOut}, 0.1);
    TweenMax.staggerTo($openMenu.find(".burguer"), 1.4, {
      x: "0%",
      autoAlpha: 1,
      ease: Expo.easeOut,
      onComplete: function() {
        $openMenu.find(".burguer-wrapper").removeClass("hidden");
        TweenMax.set($openMenu.find(".burguer"), {clearProps: "all"});
        $openMenu.css("pointer-events", "visible");
      }
    }, 0.1);
  }

  var frameTooltipLeft = tippy('.js-tooltip-left', {
    html: '#tooltip-theme-left',
    arrow: true,
    theme: 'velhotes-tooltip'
  });

  var frameTooltipMiddle = tippy('.js-tooltip-middle', {
    html: '#tooltip-theme-middle',
    arrow: true,
    theme: 'velhotes-tooltip'
  });

  var frameTooltipRight = tippy('.js-tooltip-right', {
    html: '#tooltip-theme-right',
    arrow: true,
    theme: 'velhotes-tooltip'
  });

  $yesBtn.on("click", function() {
    $mask.addClass("in");
    TweenMax.set($("#create-meme-btn").parent().parent(), {autoAlpha:1});
    if(!$_body.hasClass("phone"))
      if (!$_html.hasClass("ie")) {
        TweenMax.to($headerMain.find(".velhotes-logo"), 0.8, {y: 0, force3D: true, ease: Expo.easeOut});
        TweenMax.to($headerMain.find(".header-global"), 0.8, {y: 0, force3D: true, ease: Expo.easeOut});
      }
    $("#header-main").addClass("active");
    $("#header-main .letters").addClass("add-transition");

    TweenMax.to($(".velhotes-logo"), 0.8, {y: 0, force3D: true, delay: .5, ease: Expo.easeOut});

    $.doTimeout(100, function(){
      TweenMax.to(".velhotes-logo", 1, {y: 0, ease: Expo.easeOut});
      if ($_html.hasClass("ie")) {
        TweenMax.to(".velhotes-logo svg", 1, {scale: 1.4, autoAlpha: 1, ease: Expo.easeOut});
      } else {
        TweenMax.to(".velhotes-logo svg", 1, {scale: 1, autoAlpha: 1, ease: Expo.easeOut});
      }
      breakTitleLetters($memeLinkCria);
      TweenMax.set("#create-meme-btn", {autoAlpha:1});
      _customScroll.registerEvents(/scroll/, /wheel/, /touchstart/);
    });

    $.doTimeout(500, function(){
      if($_body.hasClass("phone")) {
        TweenMax.set($(".create-meme-link"), {autoAlpha: 1});
        TweenMax.set($(".sort-by-btn"), {autoAlpha: 1});
      }

      TweenMax.set($memeLinkCria, {autoAlpha: 1});
      TweenMax.staggerTo($("#cria-o-meme").find(".char"), 1.4, {y: 0, autoAlpha: 1, ease: Expo.easeOut}, 0.1);

      TweenMax.set($openMenu, {autoAlpha:1});
      TweenMax.staggerTo($openMenu.find(".text.open .char"), 1.4, {y: 0, autoAlpha: 1, ease: Expo.easeOut}, 0.1);
      TweenMax.staggerTo($openMenu.find(".burguer"), 1.4, {
        x: "0%",
        autoAlpha: 1,
        ease: Expo.easeOut,
        onComplete: function() {
          $openMenu.find(".burguer-wrapper").removeClass("hidden");
          TweenMax.set($openMenu.find(".burguer"), {clearProps: "all"});
          $openMenu.css("pointer-events", "visible");
        }
      }, 0.1);
    })

    $.doTimeout(900, function(){
        $("#smile-container").addClass("openSmile");
        $("#create-meme-btn").addClass("openFace");
    })
    $.doTimeout(1300, function() {
        TweenMax.to("#smile-container #wink-container span:first-of-type", 0.6, {rotation: 35, force3D: true, ease: Expo.easeOut});
        TweenMax.to("#smile-container #wink-container span:last-of-type", 0.6, {rotation: -35, force3D: true, ease: Expo.easeOut});
    })

    $.doTimeout(2000, function() {
      $mask.hide();
      _customScroll.registerEvents(/scroll/, /wheel/, /touchstart/);
    });
  });

  var offset_top_middle = Number($timeline.find(".pointer .medium").offset().top.toFixed(0)) + Number(_globalViewportH);
  var offset_teste = $(".line-full-1")[0].getTotalLength() + 35;
  var teste = _globalViewportW * $(".line-full-1")[0].getTotalLength()/1650;
  var bola = Number($timeline.find(".middle-point").offset().top.toFixed(0));

  // console.log(($(".line-full-1")[0].getBoundingClientRect().width.toFixed(0) * $(".line-full-1")[0].getBoundingClientRect().height.toFixed(0)) / $(".line-full-1")[0].getTotalLength());

  function legadoPage_scroll_rAF(status) {
    if(_customScroll == null) {
      var status = {offset: {y : -1 }};
      status.offset.y = window.pageYOffset;
    }

    if(!$_body.hasClass("mobile")) {
      var _offsetTopMiddlePoint = Number($timeline.find(".middle-point").offset().top.toFixed(0)),
          _offsetTopMiddlePointer = Number($timeline.find(".pointer").offset().top.toFixed(0)),
          _offsetTopMiddlePointerMedium = Number($timeline.find(".pointer .medium").offset().top.toFixed(0)),
          _offsetTopFrame = Number($timeline.find(".frame-svg").offset().top.toFixed(0)),
          _offsetTopStart = Number($timeline.find(".start-point").offset().top.toFixed(0));

      // normalizedScroll = normalizedValue(status.offset.y, status.limit.y, 0);
      // normalizedScroll = convertRange( scroll pagina, [ onde começa, limite do scroll ], [ numero minimo, numero maximo ] ).toFixed(0);
      kilometeresRange = convertRange( _customScroll.offset.y, [ 0, bola ], [ 2084, 0 ] ).toFixed(0);
      yearRange = convertRange( _customScroll.offset.y, [ bola, (_customScroll.limit.y - _globalViewportH) ], [ 1934, _currentYear ] ).toFixed(0);

      scaleBottle = convertRange( _customScroll.offset.y, [ _customScroll.offset.y - $(".page-footer").height() + 200, _customScroll.limit.y ], [ 0, 1 ] );

      // console.log(_customScroll.offset.y);

      TweenMax.to($(".page-footer img"), .5, {
        scale: scaleBottle,
        force3D: true
      });

      if(_scrollDirection == "down") {
        if(_offsetTopMiddlePointerMedium < _offsetTopMiddlePoint) {
          $timelineText.find("text").text(kilometeresRange + "kms");
        }
        else {
          $timelineText.find("text").text(yearRange);
          TweenMax.to($timelineText.find("text"), 1, {
            x: "-90px",
            ease: Power4.easeOut
          });
          TweenMax.to($timelineText, .25, {
            opacity: 1
          });
        }

        if(_offsetTopFrame <= _offsetTopMiddlePointerMedium && !$timelineFrame.hasClass("js-anime-down")) {
          $timelineFrame.addClass("js-anime-down");

          TweenMax.to($timelineFrame, 1, {
            rotation: -10,
            transformOrigin: "center top",
            ease: Bounce.easeOut
          });
        }

        if(_offsetTopMiddlePointer > _offsetTopStart && !$timelineStartPoint.hasClass("js-check")) {
          $timelineStartPoint.addClass("js-check");
          TweenMax.to($timelineText, 1, {
            opacity: 1
          });
        }

        if(verge.inViewport($controllerBox) && !$offBox.hasClass("js-anime")) {
          $offBox.addClass("js-anime");
          TweenMax.to($offBox, 1.5, {
            x: "0%",
            opacity: 1,
            ease: Power4.easeOut
          });
        }

        if(kilometeresRange <= 0 && $timelineStartPoint.hasClass("js-check") && _offsetTopMiddlePointerMedium < _offsetTopMiddlePoint) {
          TweenMax.to($timelineText, .1, {
            opacity: 0
          });
        }
      }

      if(_scrollDirection == "up") {
        if(_offsetTopMiddlePointerMedium < _offsetTopMiddlePoint) {
          $timelineText.find("text").text(kilometeresRange + "kms");
          TweenMax.to($timelineText.find("text"), 1, {
            x: "0px",
            ease: Power4.easeOut
          });
        }
        else {
          $timelineText.find("text").text(yearRange);
          // TweenMax.to($timelineText, .25, {
          //   opacity: 1
          // });
        }

        if(_offsetTopFrame >= _offsetTopMiddlePointerMedium && $timelineFrame.hasClass("js-anime-down")) {
          $timelineFrame.removeClass("js-anime-down");

          TweenMax.to($timelineFrame, 1, {
            rotation: 10,
            transformOrigin: "center top",
            ease: Bounce.easeOut
          });
        }

        // console.log(_offsetTopMiddlePointer, _offsetTopStart)

        if(_offsetTopMiddlePointer <= (_offsetTopStart + 100) && $timelineStartPoint.hasClass("js-check")) {
          $timelineStartPoint.removeClass("js-check");
          TweenMax.to($timelineText, .25, {
            opacity: 0
          });
        }

        if($offBox.hasClass("js-anime")) {
          $offBox.removeClass("js-anime");
          TweenMax.to($offBox, 1, {
            x: "100%",
            opacity: 0,
            ease: Power4.easeOut
          });
        }

        if(kilometeresRange > 0 && $timelineStartPoint.hasClass("js-check")) {
          TweenMax.to($timelineText, .25, {
            opacity: 1
          });
        }
      }

      _scrollAnimations.update();

      if(verge.inViewport($arriveSection, -_globalViewportH/3) && !$arriveSection.hasClass("js-video-play")) {
        $arriveSection.addClass("js-video-play");
        $videoAnime.filter(".video-second")[0].play();
      }

      if(verge.inViewport($frameSection, -_globalViewportH/3) && !$frameSection.hasClass("js-video-play")) {
        $frameSection.addClass("js-video-play");
        $videoAnime.filter(".video-third")[0].play();
      }

      if(verge.inViewport($timeline, -200)) {
        timelineAnimation.progress((_customScroll.offset.y - timeline_offset + _globalViewportH - 200)/(_customScroll.limit.y - _globalViewportH));
        timelineAnimationText.progress((_customScroll.offset.y - timeline_offset + _globalViewportH - 200)/(_customScroll.limit.y - _globalViewportH));
      }

      $imageAnime.each(function() {
        var $this = $(this);

        if(verge.inViewport($this) && !$this.hasClass("js-anime")) {
          $this.addClass("js-anime");

          TweenMax.to($this, 1.5, {
            x: 0,
            opacity: 1,
            delay: .5,
            ease: Power2.easeOut
          });
        }
      });
    }

    if(verge.inViewport($(".page-footer")) && !$sloganFooter.hasClass("change-spacing")) {
      $sloganFooter.addClass("change-spacing");
    }

    $bottleParallaxSecond.each(function() {
      var $this = $(this);

      if(verge.inViewport($this) && !$this.hasClass("js-anime")) {
        $this.addClass("js-anime");
        TweenMax.staggerTo($this, 1, {
          y: 0,
          opacity: 1,
          delay: .5,
          ease: Power4.easeOut
        }, .5);
      }
    });

    if (!$_html.hasClass("ie")) {
      // $headerGlobal.css({"top" : status.offset.y +"px"});
      // if(_scrollY > 0 && !$headerGlobal.hasClass("active")) {
      //   TweenMax.set($headerMain.find(".header-global"), {
      //     className:"+=active"
      //   });
      //   $headerGlobal.addClass("active");
      // } else if(_scrollY == 1 && $headerGlobal.hasClass("active")) {
      //   $headerGlobal.removeClass("active");
      // }
    }
  }

} //////end function homePage